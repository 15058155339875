import { useQuery } from '@tanstack/react-query';

import { useNavigateToPortfolio } from '@hcs/hc-products';
import { PortfolioFields, PortfolioPayloadWithId } from '@hcs/types';

import { PortfolioApi } from '../api';
import { useAccessibleNotifications } from '../hooks/useAccessibleNotification';
import { removeUnsubscribedPortfolioEvents } from '../utils/portfolio.utils';

export const QUERY_KEY_PORTFOLIO = 'portfolio';
export const usePortfolio = (
  portfolioId: PortfolioPayloadWithId[PortfolioFields.PortfolioId]
) => {
  const navigateToPortfolioDashboard = useNavigateToPortfolio();
  const { data: accessibleNotifications } = useAccessibleNotifications();

  return useQuery(
    [QUERY_KEY_PORTFOLIO, portfolioId, accessibleNotifications],
    async () => {
      const portfolio = await PortfolioApi.fetchPortfolio(portfolioId);
      removeUnsubscribedPortfolioEvents(portfolio, accessibleNotifications);

      return portfolio;
    },
    {
      enabled: portfolioId !== null,
      onError: navigateToPortfolioDashboard,
    }
  );
};
