export enum BrokerLicenseField {
  Number = 'number',
  State = 'state',
  Expiration = 'expiration',
}

export interface BrokerLicense {
  [BrokerLicenseField.Number]?: string | null;
  [BrokerLicenseField.State]?: string | null;
  [BrokerLicenseField.Expiration]?: string | null;
}
export interface Personalization {
  ackPrompt: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  brokerLicenses?: BrokerLicense[] | null;
  city: string | null;
  companyLogoUrl: string | null;
  companyName: string | null;
  createdAt: string | null;
  email: string | null;
  firstName: string | null;
  id: number;
  includeAddressLine1: boolean;
  includeAddressLine2: boolean;
  includeAskQuestion: boolean;
  includeCityStateZipcode: boolean;
  includeCompanyLogo: boolean;
  includeCompanyName: boolean;
  includeEmail: boolean;
  includeLicenseNumber?: boolean;
  includeLicenseState?: boolean;
  includeLicenseExpiration?: boolean;
  includeName: boolean;
  includePhone: boolean;
  includePhoto: boolean;
  includeWebsite: boolean;
  lastName: string | null;
  licenseNumber?: string | null;
  licenseState?: string | null;
  licenseExpiration?: string | null;
  organizationId: number;
  phone: string | null;
  photoUrl: string | null;
  state: string | null;
  updatedAt: string | null;
  userEmail: string | null;
  userId: number;
  websiteUrl: string | null;
  zipcode: string | null;
  signatureUrl?: string | null;
  includeSignature?: boolean;
}
