import { useAccount, useCurrentAppConfig } from '@hcs/auth';
import { GlobalEngagementEventFields } from '@hcs/types';

import { getPageGroupFromUrl } from '../utils';

import { useGlobalEngagementEventSlice } from './useGlobalEngagementEventSlice';

export const useGlobalEngagementEventFields =
  (): GlobalEngagementEventFields => {
    const { appSlug } = useCurrentAppConfig();
    const accountQuery = useAccount();
    // Add any additional global data fields that have been registered to the rtk slice
    const { state: globalDataSlice } = useGlobalEngagementEventSlice();
    return {
      app: appSlug,
      pageGroup: getPageGroupFromUrl(),
      httpPath: window.location.pathname,
      httpReferrer: document.referrer,
      authUserId: accountQuery.data?.user.id,
      email: accountQuery.data?.user.email,
      authOrganizationSlug: accountQuery.data?.currentOrganization.slug,
      authOrganizationId: accountQuery.data?.currentOrganization.id,
      ...globalDataSlice.globalEngagementEventFields,
    };
  };
