import React, { useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import classNames from 'classnames';

import { AddIcon, Button, Input, StateDropdown } from '@hcs/design-system';
import { BrokerLicenseField, Personalization } from '@hcs/types';

import styles from './PersonalizationForm.module.css';

export const licenseTemplate = {
  [BrokerLicenseField.Number]: null,
  [BrokerLicenseField.State]: null,
  [BrokerLicenseField.Expiration]: null,
};

export const BrokerLicensesField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<Personalization>();

  const { fields, append, remove } = useFieldArray<
    Personalization,
    'brokerLicenses'
  >({
    control: control,
    name: 'brokerLicenses',
  });
  const brokerLicenseValuesObj = useWatch({
    name: 'brokerLicenses',
    control: control,
  });

  useEffect(() => {
    if (brokerLicenseValuesObj?.length === 0) {
      append(licenseTemplate);
    }
  }, [brokerLicenseValuesObj, append]);

  const dataHcName = 'broker-licenses-field';
  const newLicense =
    brokerLicenseValuesObj?.[brokerLicenseValuesObj?.length - 1] || {};
  const validNewLicense = !!(
    newLicense?.[BrokerLicenseField.Number] &&
    newLicense?.[BrokerLicenseField.State] &&
    newLicense?.[BrokerLicenseField.Expiration]
  );

  return (
    <>
      <div className={styles.LicenseFieldContainer}> License</div>
      {fields.map((item, index) => {
        return (
          <div className={styles.LicenseContainer} key={item.id}>
            <Controller
              name={`brokerLicenses.${index}`}
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                const { ref, ...rest } = field;
                const eachErrorMsg =
                  errors?.['brokerLicenses'] &&
                  errors?.['brokerLicenses'][index]?.message;
                const eachErrorMsgOfNumber = errors?.['brokerLicenses']?.[
                  index
                ]?.[BrokerLicenseField.Number]?.message?.replace(
                  `brokerLicenses[${index}].number`,
                  'License Number'
                );
                const eachErrorMsgOfState = errors?.['brokerLicenses']?.[
                  index
                ]?.[BrokerLicenseField.State]?.message?.replace(
                  `brokerLicenses[${index}].state`,
                  'License State'
                );
                const eachErrorMsgOfExpiration = errors?.['brokerLicenses']?.[
                  index
                ]?.[BrokerLicenseField.Expiration]?.message?.replace(
                  `brokerLicenses[${index}].expiration`,
                  'License Expiration'
                );

                return (
                  <>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        License Number
                      </label>
                      <Input
                        {...field}
                        value={field.value[BrokerLicenseField.Number] || ''}
                        maxLength={35}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            [BrokerLicenseField.Number]: value,
                            [BrokerLicenseField.State]:
                              field.value[BrokerLicenseField.State],
                            [BrokerLicenseField.Expiration]:
                              field.value[BrokerLicenseField.Expiration],
                          });
                        }}
                        error={eachErrorMsgOfNumber}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        State
                      </label>
                      <StateDropdown
                        // field includes a ref property so it caused
                        // "Warning: Function components cannot be given refs" on the dropdown field.
                        // Since Controller already takes care of the registration process,
                        // we can just remove ref from field to prevent this warning.
                        {...rest}
                        className={classNames(
                          styles.Input,
                          styles.StateDropdown
                        )}
                        theme={{ DropdownError: styles.DropdownError }}
                        value={field.value[BrokerLicenseField.State] || ''}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onSelect={(value: string) => {
                          field.onChange({
                            [BrokerLicenseField.Number]:
                              field.value[BrokerLicenseField.Number],
                            [BrokerLicenseField.State]: value,
                            [BrokerLicenseField.Expiration]:
                              field.value[BrokerLicenseField.Expiration],
                          });
                        }}
                        error={eachErrorMsgOfState}
                      />
                    </div>

                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        License Expiration
                      </label>
                      <Input
                        {...field}
                        placeholder="YYYY-MM-DD"
                        value={field.value[BrokerLicenseField.Expiration] || ''}
                        maxLength={10}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            [BrokerLicenseField.Number]:
                              field.value[BrokerLicenseField.Number],
                            [BrokerLicenseField.State]:
                              field.value[BrokerLicenseField.State],
                            [BrokerLicenseField.Expiration]: value,
                          });
                        }}
                        error={eachErrorMsgOfExpiration}
                      />
                    </div>
                    <div className={styles.RemoveButtonContainer}>
                      <Button
                        type="button"
                        className={styles.RemoveButton}
                        dataHcName={`${dataHcName}-remove-value-button`}
                        label="Remove"
                        primary={false}
                        onClick={() => remove(index)}
                      />
                      {invalid && (
                        <div
                          data-hc-name={`${dataHcName}-error`}
                          className={styles.Error}
                        >
                          {eachErrorMsg}
                        </div>
                      )}
                    </div>
                  </>
                );
              }}
            />
          </div>
        );
      })}

      {errors?.['brokerLicenses']?.message && (
        <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
          {errors?.['brokerLicenses']?.message}
        </div>
      )}

      {/* Add new License */}
      <div className={styles.LicenseNewFieldContainer}>
        <div className={styles.AddLicenseButtonContainer}>
          <Button
            dataHcName={`${dataHcName}-button`}
            icon={<AddIcon />}
            label="Add License"
            disabled={!!errors?.['brokerLicenses'] || !validNewLicense}
            onClick={() => {
              append(licenseTemplate);
            }}
            secondary
            type="button"
          />
        </div>
      </div>
    </>
  );
};
