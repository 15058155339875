import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import AcquisitionExplorer from './AcquisitionExplorerIcon.svg?react';
import AgileEvaluation from './AgileEvaluationIcon.svg?react';
import AgileInsights from './AgileInsightsIcon.svg?react';
import AgileSuite from './AgileSuiteIcon.svg?react';
import Bpo from './BpoIcon.svg?react';
import CanaryAI from './CanaryAIIcon.svg?react';
import DataExplorer from './DataExplorerIcon.svg?react';
import Graph from './GraphIcon.svg?react';
import HomeMagnifyingGlass from './HomeMagnifyingGlassIcon.svg?react';
import Inspections from './InspectionsIcon.svg?react';
import MarketInsights from './MarketInsightsIcon.svg?react';

export const AcquisitionExplorerIcon = (props: IconProps) =>
  useIcon(AcquisitionExplorer, props);
export const AgileEvaluationIcon = (props: IconProps) =>
  useIcon(AgileEvaluation, props);
export const AgileInsightsIcon = (props: IconProps) =>
  useIcon(AgileInsights, props);
export const AgileSuiteIcon = (props: IconProps) => useIcon(AgileSuite, props);
export const DataExplorerIcon = (props: IconProps) =>
  useIcon(DataExplorer, props);
export const HomeMagnifyingGlassIcon = (props: IconProps) =>
  useIcon(HomeMagnifyingGlass, props);
export const InspectionsIcon = (props: IconProps) =>
  useIcon(Inspections, props);
export const GraphIcon = (props: IconProps) => useIcon(Graph, props);
export const MarketInsightsIcon = (props: IconProps) =>
  useIcon(MarketInsights, props);
export const CanaryAIIcon = (props: IconProps) => useIcon(CanaryAI, props);
export const BpoIcon = (props: IconProps) => useIcon(Bpo, props);
