import { CompFields, FeatureFlags, PropertyStateFields } from '@hcs/types';
import {
  DocumentRoles,
  ReportFeatureConfig,
  ReportFeatures,
  ReportFeatureTypes,
} from '@hcs/types';

// Fields for reports w/ sale-specific content
const SALE_ADJUST_FIELDS = [
  CompFields.adjustedCompValue,
  CompFields.adjustedCompValuePerSqFt,
  CompFields.hcAdjustmentDate,
  CompFields.hcAdjustmentPropertyDetails,
];
const SALE_FIELDS = [
  PropertyStateFields.currentDistressed,
  PropertyStateFields.currentFlip,
  PropertyStateFields.currentListDate,
  PropertyStateFields.currentListingPrice,
  PropertyStateFields.currentListingPricePerSqFt,
  PropertyStateFields.currentPrice,
  PropertyStateFields.currentPricePerSqFt,
  PropertyStateFields.currentStatus,
  PropertyStateFields.currentStatusDate,
  PropertyStateFields.daysOnMarketActive,
  PropertyStateFields.daysOnMarketCumulative,
  PropertyStateFields.daysOnMarketMls,
];

const RENTAL_ADJUST_FIELDS = [
  CompFields.adjustedCompValueRental,
  CompFields.adjustedCompValuePerSqFtRental,
  CompFields.hcAdjustmentDateRental,
  CompFields.hcAdjustmentPropertyDetailsRental,
];
// Fields for reports w/ rental-specific content
const RENTAL_FIELDS = [
  PropertyStateFields.currentListDateRental,
  PropertyStateFields.currentListingPricePerSqFtRental,
  PropertyStateFields.currentListingPriceRental,
  PropertyStateFields.currentPricePerSqFtRental,
  PropertyStateFields.currentPriceRental,
  PropertyStateFields.currentStatusDateRental,
  PropertyStateFields.currentStatusRental,
  PropertyStateFields.lastCloseDateRental,
  PropertyStateFields.lastClosePricePerSqFtRental,
  PropertyStateFields.lastClosePriceRental,
];

// Fields that require sale value features
const VALUE_FIELDS = [
  PropertyStateFields.condition,
  PropertyStateFields.currentValue,
  PropertyStateFields.currentValuePerSqFt,
];

// Fields that require rental value features
const RENTAL_VALUE_FIELDS = [
  PropertyStateFields.condition,
  PropertyStateFields.currentValuePerSqFtRental,
  PropertyStateFields.currentValueRental,
  PropertyStateFields.daysOnMarketMlsRental,
];

// Fields supported on all reports
export const REPORT_BASE_PROPERTY_STATE_FIELDS = [
  PropertyStateFields.apn,
  PropertyStateFields.association,
  PropertyStateFields.associationAmenities,
  PropertyStateFields.associationFee,
  PropertyStateFields.associationFeeFrequency,
  PropertyStateFields.associationFeeIncludes,
  PropertyStateFields.associationName,
  PropertyStateFields.basement,
  PropertyStateFields.basementHas,
  PropertyStateFields.bathrooms,
  PropertyStateFields.bedrooms,
  PropertyStateFields.garageSpaces,
  PropertyStateFields.garageType,
  PropertyStateFields.parking,
  PropertyStateFields.parkingTotal,
  PropertyStateFields.lastCloseDate,
  PropertyStateFields.lastClosePrice,
  PropertyStateFields.lastClosePricePerSqFt,
  PropertyStateFields.livingArea,
  PropertyStateFields.listingAgentOffice,
  PropertyStateFields.listingAgentOfficeRental,
  PropertyStateFields.lotSize,
  PropertyStateFields.roomsTotal,
  PropertyStateFields.ownerOccupied,
  PropertyStateFields.pool,
  PropertyStateFields.cooling,
  PropertyStateFields.heating,
  PropertyStateFields.propertyType,
  PropertyStateFields.stories,
  PropertyStateFields.subdivisionName,
  PropertyStateFields.taxAmount,
  PropertyStateFields.taxYear,
  PropertyStateFields.yearBuilt,
  PropertyStateFields.zoning,
];

export const REPORT_FEATURE_CONFIGS: {
  [key in ReportFeatures]: ReportFeatureConfig;
} = {
  [ReportFeatures.AppraisalComps]: {
    name: 'Appraisal Comps',
    description:
      'Users provide comps selected on an appraisal along with their adjusted values',
    type: ReportFeatureTypes.Appraisal,
    reportFeature: ReportFeatures.AppraisalComps,
    documentRoles: [
      DocumentRoles.AppraisalSubjectValue,
      DocumentRoles.AppraisalComp,
      DocumentRoles.AppraisalCompDataSources,
      DocumentRoles.AppraisalCompPhotos,
      DocumentRoles.AppraisalCompTransactionHistory,
    ],
    fields: [...SALE_FIELDS, CompFields.appraisalTotalDollarAdjustment],
  },
  [ReportFeatures.CompTop4]: {
    name: 'Top 4 Comps',
    description:
      'Top 4 active and top 4 sold comps, prioritizing selected comps, then using the competitive comps lists',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompTop4,
    documentRoles: [
      DocumentRoles.ActiveTop4Comp,
      DocumentRoles.ClosedTop4Comp,
      DocumentRoles.ActiveTop4CompPhotos,
      DocumentRoles.ClosedTop4CompPhotos,
    ],
    fields: [
      ...SALE_FIELDS,
      CompFields.locationSimilarity,
      CompFields.similarity,
    ],
  },
  [ReportFeatures.CompsPricingMarketStrategyComments]: {
    name: 'Comps Pricing Market Strategy Comments',
    description: 'Comps Pricing Market Strategy Comments',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsPricingMarketStrategyComments,
    documentRoles: [
      DocumentRoles.PricingMarketStrategyComments,
      DocumentRoles.CompsFarm,
      DocumentRoles.CompsFiltered,
      DocumentRoles.DaysOnMarketChart,
      DocumentRoles.SubjectValue,
      DocumentRoles.SaleToListPriceRatioChart,
    ],
  },
  [ReportFeatures.CompsSaleTopListPriceRatioCharts]: {
    name: 'Comps Sale to List Price Ratio Charts',
    description: 'Comps Sale to List Price Ratio Charts',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsPricingMarketStrategyComments,
    documentRoles: [DocumentRoles.SaleToListPriceRatioChart],
  },
  [ReportFeatures.SubjectFlood]: {
    name: 'Subject Flood',
    description: 'Subject Flood',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectFlood,
    documentRoles: [DocumentRoles.SubjectFlood],
  },
  [ReportFeatures.EditableLinks]: {
    name: 'Editable Links',
    description:
      'Short-lived links that are editable without additional authentication.',
    type: ReportFeatureTypes.Sharing,
    reportFeature: ReportFeatures.EditableLinks,
    documentRoles: [],
    featureFlag: FeatureFlags.PexpEditableLinks,
    reportPreferences: {
      supportDefault: false,
      values: [
        {
          reportPreferencesKey: 'publicLinksEditable',
          acceptableValues: [true],
        },
      ],
    },
  },
  [ReportFeatures.AppraisalSubjectValue]: {
    name: 'Appraisal Subject Value',
    description:
      'Users provide the final appraised value of the subject property',
    type: ReportFeatureTypes.Appraisal,
    reportFeature: ReportFeatures.AppraisalSubjectValue,
    documentRoles: [DocumentRoles.AppraisalSubjectValue],
    fields: [...SALE_FIELDS, CompFields.appraisalTotalDollarAdjustment],
  },
  [ReportFeatures.AppraisalComparison]: {
    name: 'Appraisal Comparison',
    description: 'Upload an appraisal to evaluate it for risk.',
    type: ReportFeatureTypes.Appraisal,
    reportFeature: ReportFeatures.AppraisalComparison,
    documentRoles: [
      DocumentRoles.AppraisalComp,
      DocumentRoles.AppraisalCompDataSources,
      DocumentRoles.AppraisalCompPhotos,
      DocumentRoles.AppraisalComparison,
      DocumentRoles.AppraisalInput,
      DocumentRoles.AppraisalSubject,
      DocumentRoles.AppraisalSubjectValue,
      DocumentRoles.AppraisalXml,
    ],
  },
  [ReportFeatures.AvmBreakdown]: {
    name: 'Avm Breakdown',
    description:
      'Detailed breakdown of the factors that influence the HouseCanary AVM for the subject property.',
    type: ReportFeatureTypes.PropertyValue,
    reportFeature: ReportFeatures.AvmBreakdown,
    fields: VALUE_FIELDS,
    documentRoles: [DocumentRoles.AvmBreakdown],
  },
  [ReportFeatures.DexpAnalysisComps]: {
    name: 'Data Explorer Comps Analysis',
    description: 'Create a Data Explorer Analysis for comps on the report.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.DexpAnalysisComps,
    fields: SALE_FIELDS,
    documentRoles: [DocumentRoles.CompsFarm],
  },
  [ReportFeatures.DexpAnalysisRentalComps]: {
    name: 'Data Explorer Rental Comps Analysis',
    description:
      'Create a Data Explorer Analysis for rental comps on the report.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.DexpAnalysisRentalComps,
    documentRoles: [DocumentRoles.RentalCompsFarm],
    fields: RENTAL_FIELDS,
  },
  [ReportFeatures.NearbyPropertiesChart]: {
    name: 'Nearby Properties Chart',
    description:
      'Histograms displaying the distribution of property information for nearby properties.',
    type: ReportFeatureTypes.Market,
    reportFeature: ReportFeatures.NearbyPropertiesChart,
    documentRoles: [DocumentRoles.NearbyFarm],
  },
  [ReportFeatures.MarketAnalysisChart]: {
    name: 'Market Analysis Chart',
    description: 'Market data.',
    type: ReportFeatureTypes.Market,
    reportFeature: ReportFeatures.MarketAnalysisChart,
    documentRoles: [DocumentRoles.MarketAnalysis],
  },
  [ReportFeatures.SimilarPropertiesChart]: {
    name: 'Similar Properties Chart',
    description:
      'Histogram displaying values for highly similar properties near the subject.',
    type: ReportFeatureTypes.Market,
    reportFeature: ReportFeatures.SimilarPropertiesChart,
    documentRoles: [DocumentRoles.SimilarPropertiesChart],
  },
  [ReportFeatures.MonthsOfSupplyChart]: {
    name: 'Months of Supply Chart',
    description:
      'Last 4 years of months of supply data for the subject market.',
    type: ReportFeatureTypes.Market,
    reportFeature: ReportFeatures.MonthsOfSupplyChart,
    documentRoles: [DocumentRoles.MonthsOfSupplyChart],
  },
  [ReportFeatures.DaysOnMarketChart]: {
    name: 'Days on Market Chart',
    description: 'Last 4 years of days on market data for the subject market.',
    type: ReportFeatureTypes.Market,
    reportFeature: ReportFeatures.DaysOnMarketChart,
    documentRoles: [DocumentRoles.DaysOnMarketChart],
  },
  [ReportFeatures.OutputWeb]: {
    name: 'Interactive Report',
    description: 'Ability to view an interactive PEXP report.',
    type: ReportFeatureTypes.Output,
    reportFeature: ReportFeatures.OutputWeb,
    documentRoles: [],
  },
  [ReportFeatures.OutputPdf]: {
    name: 'PDF Download',
    description: 'Ability to output the report type as a PDF.',
    type: ReportFeatureTypes.Output,
    reportFeature: ReportFeatures.OutputPdf,
    documentRoles: [],
  },
  [ReportFeatures.OutputExcel]: {
    name: 'Excel Download',
    description: 'Ability to output the report type as an Excel Spreadsheet.',
    type: ReportFeatureTypes.Output,
    reportFeature: ReportFeatures.OutputExcel,
    documentRoles: [],
  },
  [ReportFeatures.MlsSheet]: {
    name: 'MLS Listing Sheet PDF Download',
    description:
      'Ability to download a PDF of each listing in the subject transaction history.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.MlsSheet,
    documentRoles: [],
  },
  [ReportFeatures.Heatmaps]: {
    name: 'Heatmaps',
    description: 'View heatmaps on interactive maps.',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.Heatmaps,
    documentRoles: [],
  },
  [ReportFeatures.SubjectStreetview]: {
    name: 'Subject Street View',
    description: 'Google street view images and interactive interface.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectStreetview,
    documentRoles: [DocumentRoles.Subject],
  },
  [ReportFeatures.SubjectPhotosCurrent]: {
    name: 'Subject Photos Most Recent',
    description: 'Photos from the most recent listing only.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectPhotosCurrent,
    documentRoles: [DocumentRoles.SubjectPhotos],
  },
  [ReportFeatures.SubjectPhotosAll]: {
    name: 'Subject Photos All',
    description: 'All subject photos, including historical listings.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectPhotosAll,
    documentRoles: [DocumentRoles.SubjectPhotos],
  },
  [ReportFeatures.HcAvm]: {
    name: 'HouseCanary AVM',
    description: 'Adds the HouseCanary Sale AVM to the report.',
    reportFeature: ReportFeatures.HcAvm,
    type: ReportFeatureTypes.PropertyValue,
    documentRoles: [DocumentRoles.SubjectValue],
    fields: VALUE_FIELDS,
  },
  [ReportFeatures.ComparableValue]: {
    name: 'Comparable Value',
    description: 'Ability to select a Comparable Value for the report.',
    type: ReportFeatureTypes.PropertyValue,
    reportFeature: ReportFeatures.ComparableValue,
    documentRoles: [DocumentRoles.SubjectValue],
    fields: SALE_FIELDS,
  },
  [ReportFeatures.AdjustedAvm]: {
    name: 'Adjusted Avm',
    description:
      'Calculate an Adjusted AVM for the subject and allow it to be selected for the report.',
    type: ReportFeatureTypes.PropertyValue,
    reportFeature: ReportFeatures.AdjustedAvm,
    documentRoles: [DocumentRoles.SubjectValue],
    fields: VALUE_FIELDS,
  },
  [ReportFeatures.UserOpinionOfPrice]: {
    name: 'User Opinion of Price',
    description:
      'Allow the user to enter any value for the subject property on the report.',
    type: ReportFeatureTypes.PropertyValue,
    reportFeature: ReportFeatures.UserOpinionOfPrice,
    documentRoles: [DocumentRoles.SubjectValue],
  },
  [ReportFeatures.RentalHcAvm]: {
    name: 'HouseCanary Rental AVM',
    description: 'Adds the HouseCanary Rental AVM to the report.',
    type: ReportFeatureTypes.PropertyValueRental,
    reportFeature: ReportFeatures.RentalHcAvm,
    documentRoles: [DocumentRoles.RentalSubjectValue],
    fields: RENTAL_VALUE_FIELDS,
  },
  [ReportFeatures.RentalComparableValue]: {
    name: 'Rental Comparable Value',
    description: 'Ability to select a Rental Comparable Value for the report.',
    type: ReportFeatureTypes.PropertyValueRental,
    reportFeature: ReportFeatures.RentalComparableValue,
    documentRoles: [DocumentRoles.RentalSubjectValue],
    fields: RENTAL_FIELDS,
  },
  [ReportFeatures.RentalUserOpinionOfPrice]: {
    name: 'Rental User Opinion of Price',
    description:
      'Allow the user to enter any rental value for the subject property on the report.',
    type: ReportFeatureTypes.PropertyValueRental,
    reportFeature: ReportFeatures.RentalUserOpinionOfPrice,
    documentRoles: [DocumentRoles.RentalSubjectValue],
  },
  [ReportFeatures.CompsHcSuggested]: {
    name: 'HouseCanary Suggested Comps',
    description:
      'Provide a list of suggested comps based on Adjusted Similarity Score',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsHcSuggested,
    documentRoles: [
      DocumentRoles.HcSuggestedComp,
      DocumentRoles.CompValueHcSuggested,
      DocumentRoles.Comp,
    ],
    fields: SALE_FIELDS,
  },
  [ReportFeatures.CompsSelect]: {
    name: 'Select Comps',
    description: 'Allow users to select their own comps.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsSelect,
    documentRoles: [
      DocumentRoles.Comp,
      DocumentRoles.CompsFarm,
      DocumentRoles.CompsFiltered,
      DocumentRoles.CompsFilters,
    ],
    fields: SALE_FIELDS,
  },
  [ReportFeatures.CompsFarmView]: {
    name: 'View Comps Farm List',
    description: 'Allow users to view the full comps farm list.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsFarmView,
    documentRoles: [
      DocumentRoles.Comp,
      DocumentRoles.CompsFarm,
      DocumentRoles.CompsFiltered,
      DocumentRoles.CompsFilters,
    ],
    fields: SALE_FIELDS,
  },
  [ReportFeatures.CompsAdjust]: {
    name: 'Adjust Comps',
    description:
      'Allow users to add dollar adjustments to selected comps and account for the adjustments when calculating the Comparable Value.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsAdjust,
    documentRoles: [DocumentRoles.Comp],
    fields: [...SALE_FIELDS, CompFields.userTotalDollarAdjustment],
  },
  [ReportFeatures.CompsSavedFilterSets]: {
    name: 'Save Comp Filters',
    description: 'Allow users to save and apply comp filter sets',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsSavedFilterSets,
    documentRoles: [DocumentRoles.CompsFilters],
    fields: SALE_FIELDS,
  },
  [ReportFeatures.CompsEditDetails]: {
    name: 'Edit Comp Property Details',
    description: 'Allow users to edit comp property details.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsEditDetails,
    documentRoles: [DocumentRoles.Comp],
    fields: SALE_FIELDS,
  },
  [ReportFeatures.CompsAdjustedAvm]: {
    name: 'Comp Adjusted Avm',
    description:
      'Calculate an Adjusted AVM for the comp if the user changes the comp property details.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsAdjustedAvm,
    documentRoles: [DocumentRoles.Comp],
    fields: VALUE_FIELDS,
  },
  [ReportFeatures.CompsFilterGeojson]: {
    name: 'Comps Filter Custom Shape',
    description: 'Allow users to draw a custom shape comps filter.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsFilterGeojson,
    documentRoles: [
      DocumentRoles.CompsFilters,
      DocumentRoles.CompsFarm,
      DocumentRoles.CompsFiltered,
      DocumentRoles.CompsFilters,
    ],
  },
  [ReportFeatures.CompsFilterKeyword]: {
    name: 'Comps Filter Keyword',
    description: 'Filter the comps farm list by listing remarks keywords.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsFilterKeyword,
    documentRoles: [],
  },
  [ReportFeatures.CompsFilterDistance]: {
    name: 'Comps Filter Distance',
    description: 'Allow comp filtering by distance from the subject.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsFilterDistance,
    documentRoles: [
      DocumentRoles.CompsFilters,
      DocumentRoles.CompsFarm,
      DocumentRoles.CompsFiltered,
      DocumentRoles.CompsFilters,
    ],
  },
  [ReportFeatures.CompsSelectByAddress]: {
    name: 'Comps Select By Address',
    description: 'Search for any comp by address and it to the report.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsSelectByAddress,
    documentRoles: [DocumentRoles.Comp],
  },
  [ReportFeatures.CompsSelectByListing]: {
    name: 'Comps Select By Listing',
    description: 'Search for any comp by listing and it to the report.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsSelectByListing,
    documentRoles: [DocumentRoles.Comp],
    fields: SALE_FIELDS,
  },
  [ReportFeatures.CompsHcAdjustmentDate]: {
    name: 'Comps HouseCanary Date Adjustment',
    description: 'Include the HouseCanary HPI comp dollar value adjustment.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsHcAdjustmentDate,
    documentRoles: [
      DocumentRoles.Comp,
      DocumentRoles.CompsFarm,
      DocumentRoles.SubjectValue,
    ],
    fields: SALE_ADJUST_FIELDS,
  },
  [ReportFeatures.CompsHcAdjustmentDetails]: {
    name: 'Comps HouseCanary Property Details Adjustment',
    description:
      'Include the HouseCanary property details comp dollar value adjustment.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsHcAdjustmentDetails,
    documentRoles: [
      DocumentRoles.Comp,
      DocumentRoles.CompsFarm,
      DocumentRoles.SubjectValue,
    ],
    fields: SALE_ADJUST_FIELDS,
  },
  [ReportFeatures.CompsListingRemarks]: {
    name: 'Comps Listing Remarks',
    description: 'Include comp listing remarks',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsListingRemarks,
    documentRoles: [DocumentRoles.CompsFarm],
  },
  [ReportFeatures.CompsNearbyFilteredCharts]: {
    name: 'Filtered Comps Nearby Properties Charts',
    description:
      'Histograms displaying the distribution of property information for the filtered farm list.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsNearbyFilteredCharts,
    documentRoles: [
      DocumentRoles.CompsFarm,
      DocumentRoles.CompsFilters,
      DocumentRoles.CompsFiltered,
      DocumentRoles.NearbyFiltered,
    ],
  },
  [ReportFeatures.CompsSimilarPropertiesCharts]: {
    name: 'Filtered Comps Similar Properties Charts',
    description:
      'Histogram displaying values for highly similar properties in the filtered farm list.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsSimilarPropertiesCharts,
    documentRoles: [
      DocumentRoles.CompsFarm,
      DocumentRoles.CompsFilters,
      DocumentRoles.CompsFiltered,
      DocumentRoles.SimilarPropertiesChartFiltered,
    ],
  },
  [ReportFeatures.CompsPhotosCurrent]: {
    name: 'Comps Photos Most Recent',
    description: 'Include the comp photos from the most recent listing.',
    reportFeature: ReportFeatures.CompsPhotosCurrent,
    type: ReportFeatureTypes.Comps,
    documentRoles: [
      DocumentRoles.CompPhotos,
      DocumentRoles.HcSuggestedCompPhotos,
    ],
  },
  [ReportFeatures.CompsTransactionHistory]: {
    name: 'Comps Transaction History',
    description: 'Include transaction history for all comps.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsTransactionHistory,
    documentRoles: [DocumentRoles.CompTransactionHistory],
  },
  [ReportFeatures.CompsStreetview]: {
    name: 'Comps Street View',
    description: 'Include Google Street View image and interactive UI.',
    type: ReportFeatureTypes.Comps,
    reportFeature: ReportFeatures.CompsStreetview,
    documentRoles: [],
  },
  [ReportFeatures.Tags]: {
    name: 'Comps Tags',
    description: 'Add Tags to selected comps.',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.Tags,
    documentRoles: [],
  },
  [ReportFeatures.ReportDataRefresh]: {
    name: 'Refresh Report Data',
    description:
      'Ability to refresh the underlying data on the report and re-apply user changes on top of it.',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.ReportDataRefresh,
    documentRoles: [],
  },
  [ReportFeatures.ReportEditHistory]: {
    name: 'Report Edit History',
    description: 'Ability to see detailed edit history',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.ReportEditHistory,
    documentRoles: [],
  },
  [ReportFeatures.SubjectTransactionHistory]: {
    name: 'Subject Transaction History',
    description: 'Include subject transaction history.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectTransactionHistory,
    documentRoles: [DocumentRoles.SubjectTransactionHistory],
  },
  [ReportFeatures.SubjectForecastZipcode]: {
    name: 'Subject Forecast Zipcode',
    description: 'Include 3 year subject value forecast based on the Zipcode.',
    type: ReportFeatureTypes.PropertyValue,
    reportFeature: ReportFeatures.SubjectForecastZipcode,
    documentRoles: [DocumentRoles.ForecastChartZip],
  },
  [ReportFeatures.SubjectForecastBlock]: {
    name: 'Subject Forecast Block',
    description: 'Include 1 year subject value forecast based on the Block.',
    type: ReportFeatureTypes.PropertyValue,
    reportFeature: ReportFeatures.SubjectForecastBlock,
    documentRoles: [DocumentRoles.ForecastChartBlock],
  },
  [ReportFeatures.SubjectPropertyDetails]: {
    name: 'Subject Property Details',
    description:
      'Basic subject property details. Should include in all reports.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectPropertyDetails,
    documentRoles: [DocumentRoles.Subject],
  },
  [ReportFeatures.SubjectDataSources]: {
    name: 'Subject Data Sources',
    description:
      'Breakdown of subject property data sources from MLS, Public Record, and "Best of HC"',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectDataSources,
    documentRoles: [DocumentRoles.SubjectDataSources],
  },
  [ReportFeatures.SubjectListingRemarks]: {
    name: 'Subject Listing Remarks',
    description: 'Include subject listing remarks.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectListingRemarks,
    documentRoles: [DocumentRoles.Subject],
  },
  [ReportFeatures.SubjectEditDetails]: {
    name: 'Subject Edit Details',
    description: 'Allow users to edit subject property details.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectEditDetails,
    documentRoles: [DocumentRoles.Subject],
    fields: VALUE_FIELDS,
  },
  [ReportFeatures.SubjectEditCondition]: {
    name: 'Subject Edit Condition',
    description: 'Allow users to edit subject condition.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectEditCondition,
    documentRoles: [DocumentRoles.Subject],
    fields: VALUE_FIELDS,
  },
  [ReportFeatures.SubjectComment]: {
    name: 'Subject Comment',
    description: 'Allow users to leave a comment about the subject property.',
    type: ReportFeatureTypes.SubjectProperty,
    reportFeature: ReportFeatures.SubjectComment,
    documentRoles: [DocumentRoles.Subject],
  },
  [ReportFeatures.CobrandedReport]: {
    name: 'Cobrand Report',
    description: 'Allow cobranding/personalization of the report.',
    type: ReportFeatureTypes.Sharing,
    reportFeature: ReportFeatures.CobrandedReport,
    documentRoles: [],
  },
  [ReportFeatures.IntraOrgLinks]: {
    name: 'Intra-Org Report Links',
    description:
      'Allow other users inside your organization access to the report.',
    type: ReportFeatureTypes.Sharing,
    reportFeature: ReportFeatures.IntraOrgLinks,
    documentRoles: [],
    reportPreferences: {
      supportDefault: true,
      values: [
        {
          reportPreferencesKey: 'intraOrgReportAccess',
          acceptableValues: ['editable', 'visible'],
        },
      ],
    },
  },
  [ReportFeatures.PublicLinks]: {
    name: 'Public Report Links',
    description:
      'Allow creation of read-only report links that can be viewed without any authentication.',
    type: ReportFeatureTypes.Sharing,
    reportFeature: ReportFeatures.PublicLinks,
    documentRoles: [],
    reportPreferences: {
      supportDefault: false,
      values: [
        {
          reportPreferencesKey: 'publicLinkPermissions',
          acceptableValues: ['read-only'],
        },
        {
          reportPreferencesKey: 'publicLinksReadOnly',
          acceptableValues: [true],
        },
      ],
    },
  },
  [ReportFeatures.LimitedReportGeolocation]: {
    name: 'Limited Reports - Missing GeoLocation',
    description:
      'Allow creation of the report when subject geo location is missing.',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.LimitedReportGeolocation,
    documentRoles: [],
  },
  [ReportFeatures.LimitedReportAvm]: {
    name: 'Limited Reports - Missing Avm',
    description:
      'Allow creation of the report when we do not have an AVM for the subject.',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.LimitedReportAvm,
    documentRoles: [],
  },
  [ReportFeatures.LimitedReportMls]: {
    name: 'Limited Reports - Missing MLS',
    description:
      'Allow creation of the report when we do not have MLS coverage for the subject.',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.LimitedReportMls,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsHcSuggested]: {
    name: 'HouseCanary Suggested Rental Comps',
    description:
      'Provide a list of suggested rental comps based on Adjusted Similarity Score',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsHcSuggested,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsFarmView]: {
    name: 'View Rental Comps Farm List',
    description: 'Allow users to view the full rental comps farm list.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsFarmView,
    documentRoles: [
      DocumentRoles.RentalComp,
      DocumentRoles.RentalCompsFarm,
      DocumentRoles.RentalCompsFiltered,
      DocumentRoles.RentalCompsFilters,
    ],
    fields: RENTAL_FIELDS,
  },
  [ReportFeatures.RentalCompsSelect]: {
    name: 'Select Rental Comps',
    description: 'Allow users to select their own rental comps.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsSelect,
    documentRoles: [
      DocumentRoles.RentalComp,
      DocumentRoles.RentalCompsFarm,
      DocumentRoles.RentalCompsFiltered,
      DocumentRoles.RentalCompsFilters,
    ],
    fields: RENTAL_FIELDS,
  },
  [ReportFeatures.RentalCompsAdjust]: {
    name: 'Adjust Rental Comps',
    description:
      'Allow users to add dollar adjustments to selected rental comps and account for the adjustments when calculating the Comparable Value.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsAdjust,
    documentRoles: [DocumentRoles.RentalComp],
    fields: [...RENTAL_VALUE_FIELDS, CompFields.userTotalDollarAdjustment],
  },
  [ReportFeatures.RentalCompsSavedFilterSets]: {
    name: 'Save Rental Comp Filters',
    description: 'Allow users to save and apply rental comp filter sets',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsSavedFilterSets,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsEditDetails]: {
    name: 'Edit Rental Comp Property Details',
    description: 'Allow users to edit rental comp property details.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsEditDetails,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsFilterGeojson]: {
    name: 'Rental Comps Filter Custom Shape',
    description: 'Allow users to draw a custom shape rental comps filter.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsFilterGeojson,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsFilterKeyword]: {
    name: 'Rental Comps Filter Keyword',
    description:
      'Filter the rental comps farm list by listing remarks keywords.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsFilterKeyword,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsFilterDistance]: {
    name: 'Rental Comps Filter Distance',
    description: 'Allow rental comp filtering by distance from the subject.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsFilterDistance,
    documentRoles: [
      DocumentRoles.RentalCompsFarm,
      DocumentRoles.RentalCompsFiltered,
      DocumentRoles.RentalCompsFilters,
    ],
  },
  [ReportFeatures.RentalCompsSelectByAddress]: {
    name: 'Rental Comps Select By Address',
    description: 'Search for any rental comp by address and it to the report.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsSelectByAddress,
    documentRoles: [DocumentRoles.RentalComp],
    fields: RENTAL_FIELDS,
  },
  [ReportFeatures.RentalCompsSelectByListing]: {
    name: 'Rental Comps Select By Listing',
    description: 'Search for any rental comp by listing and it to the report.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsSelectByListing,
    documentRoles: [DocumentRoles.RentalComp],
    fields: RENTAL_FIELDS,
  },
  [ReportFeatures.RentalCompsHcAdjustmentDate]: {
    name: 'Rental Comps HouseCanary Date Adjustment',
    description:
      'Include the HouseCanary RPI rental comp dollar value adjustment.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsHcAdjustmentDate,
    documentRoles: [
      DocumentRoles.RentalComp,
      DocumentRoles.RentalCompsFarm,
      DocumentRoles.RentalSubjectValue,
    ],
    fields: RENTAL_ADJUST_FIELDS,
  },
  [ReportFeatures.RentalCompsHcAdjustmentDetails]: {
    name: 'Rental Comps HouseCanary Property Details Adjustment',
    description:
      'Include the HouseCanary property details rental comp dollar value adjustment.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsHcAdjustmentDetails,
    documentRoles: [
      DocumentRoles.RentalComp,
      DocumentRoles.RentalCompsFarm,
      DocumentRoles.SubjectValue,
    ],
    fields: RENTAL_ADJUST_FIELDS,
  },
  [ReportFeatures.RentalCompsListingRemarks]: {
    name: 'Rental Comps Listing Remarks',
    description: 'Include comp listing remarks',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsListingRemarks,
    documentRoles: [DocumentRoles.RentalCompsFarm],
  },
  [ReportFeatures.RentalCompsPhotosCurrent]: {
    name: 'Rental Comps Photos Most Recent',
    description: 'Include the rental comp photos from the most recent listing.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsPhotosCurrent,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsTransactionHistory]: {
    name: 'Rental Comps Transaction History',
    description: 'Include transaction history for all rental comps.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsTransactionHistory,
    documentRoles: [],
  },
  [ReportFeatures.RentalCompsStreetview]: {
    name: 'Rental Comps Street View',
    description: 'Include Google Street View image and interactive UI.',
    type: ReportFeatureTypes.RentalComps,
    reportFeature: ReportFeatures.RentalCompsStreetview,
    documentRoles: [],
  },
  [ReportFeatures.Inspection]: {
    name: 'Inspection',
    description: 'Order an inspection from within the report UI.',
    type: ReportFeatureTypes.Inspection,
    reportFeature: ReportFeatures.Inspection,
    documentRoles: [],
  },
  [ReportFeatures.ReconciliationComments]: {
    name: 'Reconciliation Comments',
    description: 'Add comments to the reconciliation section of the report.',
    type: ReportFeatureTypes.Market,
    reportFeature: ReportFeatures.ReconciliationComments,
    documentRoles: [DocumentRoles.ReconciliationComments],
  },
  [ReportFeatures.RealtorDetails]: {
    name: 'Realtor Details',
    description: 'Add Realtor Details to the report',
    type: ReportFeatureTypes.Data,
    reportFeature: ReportFeatures.RealtorDetails,
    documentRoles: [],
  },
};

// Constant used to determine if a given documentRole is available for the report config
export const DOCUMENT_ROLE_REPORT_FEATURES: Partial<{
  [key in DocumentRoles]: ReportFeatures[];
}> = (() => {
  const results: Partial<{
    [key in DocumentRoles]: ReportFeatures[];
  }> = {};
  Object.entries(REPORT_FEATURE_CONFIGS).forEach((entry) => {
    const { documentRoles, reportFeature } = entry[1];
    documentRoles.forEach((documentRole) => {
      const documentRoleModules = results[documentRole] || [];
      results[documentRole] = [
        ...new Set([...documentRoleModules, reportFeature]),
      ];
    });
  });
  return results;
})();

// Constant used to determine if a given documentRole is available for the report config
export const REPORT_FEATURE_TYPE_FEATURES: Partial<{
  [key in ReportFeatureTypes]: ReportFeatures[];
}> = (() => {
  const results: Partial<{
    [key in ReportFeatureTypes]: ReportFeatures[];
  }> = {};
  Object.entries(REPORT_FEATURE_CONFIGS).forEach((entry) => {
    const { type, reportFeature } = entry[1];
    if (!results[type]) {
      results[type] = [];
    }

    results[type]?.push(reportFeature);
  });
  return results;
})();
