import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEventHandler,
  useImperativeHandle,
  useRef,
} from 'react';
import classNames from 'classnames';

import { FormError } from '../../../display/forms/FormError';

import styles from './TextArea.module.css';

export interface TextAreaProps {
  value: string;
  dataHcName: string;
  className?: string;
  label?: string;
  name?: string;
  maxLength?: number;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  onChange: (v: string) => void;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement>;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
}

type ParentRef = HTMLTextAreaElement | null;

export const TextArea = React.forwardRef<ParentRef, TextAreaProps>(
  (props: TextAreaProps, ref) => {
    const {
      value,
      label,
      maxLength,
      className,
      dataHcName,
      disabled,
      readOnly,
      name,
      error,
      placeholder,
      required,
      onChange,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
    } = props;

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => textAreaRef.current);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e) {
        const value = e.target.value || '';
        onChange(value);
      }
    };

    return (
      <div
        className={classNames(styles.TextArea, className)}
        data-hc-name={dataHcName}
      >
        {label && (
          <label data-hc-name={`${dataHcName}-label`} className={styles.Label}>
            {label}
          </label>
        )}
        <textarea
          className={styles.Input}
          value={value}
          readOnly={disabled || readOnly}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          data-hc-name={`${dataHcName}-input`}
          onChange={handleChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          ref={textAreaRef}
          required={required}
        />
        <div className={styles.Meta}>
          {error && (
            <FormError dataHcName={`${dataHcName}-error`} value={error} />
          )}
          {maxLength && (
            <div
              className={styles.ChartCount}
              data-hc-name={`${dataHcName}-char-count`}
            >
              {`${value ? value.length : 0} / ${maxLength}`}
            </div>
          )}
        </div>
      </div>
    );
  }
);
