import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { EventData } from '@hcs/types';

interface EventDataContextValue {
  eventData: EventData | null;
  setEventData: Dispatch<SetStateAction<EventData | null>>;
}

export const EventDataContext = createContext<EventDataContextValue>({
  eventData: null,
  setEventData: () => void 0,
});

export const EventDataContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [eventData, setEventData] = useState<EventData | null>(null);
  return (
    <EventDataContext.Provider value={{ eventData, setEventData }}>
      {' '}
      {children}{' '}
    </EventDataContext.Provider>
  );
};
