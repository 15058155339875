import { AppConfig,AppSlugs } from '@hcs/types';

export const APP_CONFIG_PLATFORM: AppConfig = {
  name: '',
  appSlug: AppSlugs.Platform,
  rootPath: `/platform`,
  descriptionLong: '',
  descriptionShort: '',
  feOnlyApp: true,
};
