import { useMatch } from 'react-router-dom';

import { Switch } from '@hcs/design-system';
import { HcsDevToolsPortal } from '@hcs/dev-tools';
import { useActiveState } from '@hcs/hooks';

import { CANARYAI_DEV_TOOLS_ENABLED } from '../../constants/index';
import { useCanaryAIDevToolsLocalStorageState } from '../../hooks';

const dataHcName = 'canaryai-dev-tool';

// This dev tool simply handles toggling a boolean localStorage value. The localStorage value is read
// by the CanaryAI applicaiton to determine whether to show debugging tools for the app within the UI
export const CanaryAIDevTool = () => {
  const canaryaiDevToolsLocalStorageState =
    useCanaryAIDevToolsLocalStorageState();
  const { active: canaryaiDevToolsActive, setActiveState } = useActiveState({
    activeInitial:
      canaryaiDevToolsLocalStorageState.actions.getItem(
        CANARYAI_DEV_TOOLS_ENABLED
      ) === 'true',
  });
  const isInCanaryAIApplication = useMatch('/canaryai');
  return (
    <HcsDevToolsPortal
      groups={
        isInCanaryAIApplication
          ? [
              {
                dataHcName,
                title: 'CanaryAI',
                items: [
                  {
                    type: 'item',
                    item: {
                      dataHcName: `${dataHcName}-sections-control`,
                      content: (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <span>Enable CanaryAI Devtools</span>
                          <Switch
                            dataHcName={`${dataHcName}-sections-control-toggle`}
                            onChange={(v) => {
                              // SECURITY: this is only used to enable some debugging tools for the CanaryAI application
                              // eslint-disable-next-line scanjs-rules/identifier_localStorage
                              canaryaiDevToolsLocalStorageState.actions.setItem(
                                CANARYAI_DEV_TOOLS_ENABLED,
                                JSON.stringify(v)
                              );
                              setActiveState(v);
                            }}
                            value={canaryaiDevToolsActive}
                            switchOnOption={{
                              value: true,
                            }}
                            switchOffOption={{
                              value: false,
                            }}
                          />
                        </div>
                      ),
                    },
                  },
                ],
              },
            ]
          : []
      }
    />
  );
};
