import { useCallback, useMemo } from 'react';

import { useFeatureFlagsForUser, useUiPreferencesForUser } from '@hcs/huell';
import {
  ExperienceFlagIds,
  ExperienceFlagsType,
  FeatureFlags,
  PreferencesKeys,
} from '@hcs/types';
import { ENV, IS_LOCAL } from '@hcs/webapps';

import { EXPERIENCE_FLAG_CONFIGS } from '../constants';
import { useExperienceFlagsDevLocalStorageState } from '../hooks/useExperienceFlagsDevLocalStorageState';

import { useExperienceFlagsSlice } from './useExperienceFlagsSlice';

export const useExperienceFlagsDevtools = () => {
  const { state: localStorageState } = useExperienceFlagsDevLocalStorageState();
  const { state: sliceState } = useExperienceFlagsSlice();

  return useMemo(() => {
    return {
      ...sliceState,
      ...localStorageState,
    };
  }, [sliceState, localStorageState]);
};

export const useExperienceFlagsDevtoolsWithoutForce = () => {
  const devtoolsState = useExperienceFlagsDevtools();

  return useMemo(() => {
    const results: Partial<ExperienceFlagsType> = {};
    Object.entries(devtoolsState).forEach(
      ([experienceFlagIdStr, flagDevtoolState]) => {
        const experienceFlagId = experienceFlagIdStr as ExperienceFlagIds;
        results[experienceFlagId] = flagDevtoolState.value;
      }
    );
    return results;
  }, [devtoolsState]);
};

export const useExperienceFlagsSystem = () => {
  return useMemo<ExperienceFlagsType>(() => {
    const results: Partial<ExperienceFlagsType> = {};

    for (const expFlagConfig of Object.values(EXPERIENCE_FLAG_CONFIGS)) {
      results[expFlagConfig.experienceFlagId] =
        IS_LOCAL || ENV === 'dev'
          ? expFlagConfig.envs.dev
          : expFlagConfig.envs[ENV];
    }
    return results as ExperienceFlagsType;
  }, []);
};

export const useExperienceFlagsFeatureFlagControlled = () => {
  const experienceFlagsSystem = useExperienceFlagsSystem();
  const { data: featureFlags } = useFeatureFlagsForUser();
  const featureFlagControlled = useCallback(
    (featureFlag: FeatureFlags, experienceFlag: ExperienceFlagIds): boolean => {
      return featureFlags?.[featureFlag] != null
        ? featureFlags[featureFlag]
        : experienceFlagsSystem[experienceFlag];
    },
    [featureFlags, experienceFlagsSystem]
  );
  return useMemo(() => {
    return {
      // Control PEXP Navigation for report-api migration
      PEXP_REPORT_API: featureFlagControlled(
        FeatureFlags.ReportApi,
        'PEXP_REPORT_API'
      ),
      // Control Access to Report-Api Webhook Setup
      REPORT_API_WEBHOOK: featureFlagControlled(
        FeatureFlags.ReportApiWebhook,
        'REPORT_API_WEBHOOK'
      ),
      // Control Access to Appraisal Comparison Demo
      PEXP_APPRAISAL_COMPARE: featureFlagControlled(
        FeatureFlags.AppraisalComparisonReports,
        'PEXP_APPRAISAL_COMPARE'
      ),
    };
  }, [featureFlagControlled]);
};

export const useExperienceFlagsPreferenceControlled = () => {
  const experienceFlagsSystem = useExperienceFlagsSystem();
  const { data: uiPreferencesForUser } = useUiPreferencesForUser();
  return useMemo<Partial<ExperienceFlagsType>>(() => {
    return {
      // we allow an individual user to turn off the pexp redesign
      PEXP_REDESIGN:
        uiPreferencesForUser?.[PreferencesKeys.PexpRedesignEnabled] === false
          ? false
          : uiPreferencesForUser?.[PreferencesKeys.PexpRedesignEnabled] === true
          ? true
          : experienceFlagsSystem.PEXP_REDESIGN,
    };
  }, [uiPreferencesForUser, experienceFlagsSystem]);
};

export const useExperienceFlagsWithoutDevTool = (): ExperienceFlagsType => {
  const experienceFlagsSystem = useExperienceFlagsSystem();
  const experienceFlagsFeatureFlagged =
    useExperienceFlagsFeatureFlagControlled();
  const experienceFlagsPreferences = useExperienceFlagsPreferenceControlled();
  return useMemo(() => {
    return {
      ...experienceFlagsSystem,
      // Use preferences to allow users to opt-in to beta experiences:
      ...experienceFlagsPreferences,
      // Use feature-flags to control experience flags at the org-level:
      ...experienceFlagsFeatureFlagged,
    };
  }, [
    experienceFlagsSystem,
    experienceFlagsPreferences,
    experienceFlagsFeatureFlagged,
  ]);
};

// Using this hook allows us to augment the env variables that initialize the rtk slice state
export const useExperienceFlags = (): ExperienceFlagsType => {
  const experienceFlagsSystem = useExperienceFlagsSystem();
  const experienceFlagsFeatureFlagged =
    useExperienceFlagsFeatureFlagControlled();
  const experienceFlagsPreferences = useExperienceFlagsPreferenceControlled();
  const experienceFlagsDevtools = useExperienceFlagsDevtoolsWithoutForce();
  return useMemo(() => {
    return {
      ...experienceFlagsSystem,
      // Use preferences to allow users to opt-in to beta experiences:
      ...experienceFlagsPreferences,
      // Use feature-flags to control experience flags at the org-level:
      ...experienceFlagsFeatureFlagged,
      // Devtool can override all
      ...experienceFlagsDevtools,
    };
  }, [
    experienceFlagsSystem,
    experienceFlagsPreferences,
    experienceFlagsFeatureFlagged,
    experienceFlagsDevtools,
  ]);
};
