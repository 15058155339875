import React, {
  Children,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useActiveState } from '@hcs/hooks';
import { isElementVisible } from '@hcs/utils';
import { FIXED_QUERY_SELECTOR } from '@hcs/webapps';

import {
  CaretPosition,
  ContentPosition,
  ContentProps,
  PopoverButtonProps,
} from './types';

import styles from './Popover.module.css';

export interface PopoverTheme {
  Button?: string;
  Trigger?: string;
  Caret?: string;
  PopoverContentPosition?: string;
}

export interface CaretDimensions {
  anchor: 'center' | 'edge';
  height: number;
  width: number;
}

export type TooltipContentPosition = 'left' | 'midLeft' | 'midRight' | 'right';

export interface PopoverProps {
  trigger: React.ReactNode; // trigger must accept an onClick prop
  content: React.ReactNode;
  theme?: PopoverTheme;
  closeOnClick?: boolean; // Close popover when content is clicked
  pagePadding?: [number, number, number, number]; // Padding between PopupContent and the edge of the window
  showOnMouseEnter?: boolean; // Show popover onMouseEnter
  overlay?: 'transparent' | 'opaque';
  onClose?: VoidFunction;
  onChangeActiveState?: (active: boolean) => void;
  renderCaret?: boolean;
  contentPadding?: number;
  // Allows parent to close popup
  active?: boolean;
  // Parent defines initial state, then component takes over
  activeInitial?: boolean;
  position?: TooltipContentPosition;
  dataHcName: string;
  dataHcEventSection?: string;
  caretDimensions?: CaretDimensions;
  onSetCaretPosition?: (caretPosition: CaretPosition) => void;
  positionOffset?: {
    anchorBottom?: number;
    anchorTop?: number;
    left?: number;
  };
}

export const CARET_DIMENSIONS_DEFAULT: CaretDimensions = {
  anchor: 'center',
  width: 14,
  height: 7,
};

// Renders Popover outside of current DOM tree
const PopoverContentPortal = ({ children }: { children: ReactNode }) => {
  const elm = document.querySelector(FIXED_QUERY_SELECTOR);
  return !elm ? null : createPortal(children, elm);
};

interface PopoverButtonBounds {
  caretBounds: DOMRect | undefined;
  buttonBounds: DOMRect | undefined;
}

type PopoverButtonImperativeHandleRef = {
  boundingRect: () => PopoverButtonBounds;
  contains(e: Node | null): boolean;
  popoverButtonCurrent: HTMLSpanElement | null;
  caretCurrent: HTMLDivElement | null;
};

const PopoverButton = forwardRef<
  PopoverButtonImperativeHandleRef,
  PopoverButtonProps & { theme?: PopoverTheme; active?: boolean }
>(
  (
    {
      active,
      anchor,
      caretPosition,
      button,
      className,
      onClick,
      onMouseEnter,
      dataHcName,
      dataHcEventSection,
      theme,
    },
    ref
  ) => {
    const Button = useRef<HTMLSpanElement>(null);
    const Caret = useRef<HTMLDivElement>(null);
    useImperativeHandle(
      ref,
      () => {
        return {
          boundingRect() {
            const caretBounds = Caret?.current?.getBoundingClientRect();
            const buttonBounds = Button?.current?.getBoundingClientRect();
            return { caretBounds, buttonBounds };
          },
          contains(e: Node | null) {
            if (Caret && Caret.current && Button && Button.current) {
              if (Caret.current.contains(e)) return true;
              if (Button.current.contains(e)) return true;
            }
            return false;
          },
          popoverButtonCurrent: Button.current,
          caretCurrent: Caret.current,
        };
      },
      []
    );
    return (
      <>
        {Children.map(button, (child) => {
          // If the button is component we need to wrap it in a span to access the ClientBoundingRect when positioning
          const childHasProps = React.isValidElement(child);
          const handleMouseEnter = () => {
            onMouseEnter?.();
            if (childHasProps) {
              child.props.onMouseEnter?.();
            }
          };
          const handleOnClick = () => {
            onClick?.();
            if (childHasProps) {
              child.props.onClick?.();
            }
          };
          return (
            <span
              ref={Button}
              className={classNames(
                styles.RefWrap,
                className,
                theme?.Trigger,
                styles[anchor]
              )}
              onMouseEnter={handleMouseEnter}
              onClick={handleOnClick}
              data-hc-name={`${dataHcName}-trigger-button`}
              data-hc-event-section={dataHcEventSection}
            >
              {child}
              <div
                ref={Caret}
                data-hc-name={`${dataHcName}-popover-caret`}
                className={classNames(
                  styles.PopoverCaret,
                  styles.hiddenCaret,
                  theme?.Caret,
                  styles[anchor],
                  { [styles.active]: active }
                )}
                style={caretPosition}
              />
            </span>
          );
        })}
      </>
    );
  }
);

const PopoverContent = forwardRef(
  (props: ContentProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      className,
      content,
      contentPosition: { anchor, ...contentPosition },
      visibleCaretPosition,
      overlay,
      handleCloseOverlay,
      dataHcName,
      dataHcEventSection,
      renderCaret,
      theme,
    } = props;
    return (
      <PopoverContentPortal>
        <div
          ref={ref}
          className={classNames(
            styles.PopoverContentPosition,
            className,
            theme?.PopoverContentPosition,
            styles[anchor]
          )}
          style={{ ...contentPosition }}
          data-hc-name={`${dataHcName}-content`}
          data-hc-event-section={dataHcEventSection}
        >
          {content}
        </div>
        {renderCaret && (
          <div
            data-hc-name={`${dataHcName}-popover-caret`}
            className={classNames(
              styles.PopoverCaret,
              styles.visibleCaret,
              styles[anchor],
              theme?.Caret
            )}
            style={{
              ...visibleCaretPosition,
              width: CARET_DIMENSIONS_DEFAULT.width,
            }}
          />
        )}
        {overlay && (
          <div
            className={classNames(styles.Overlay, {
              [styles.transparent]: overlay === 'transparent',
              [styles.opaque]: overlay === 'opaque',
            })}
            onClick={handleCloseOverlay}
          />
        )}
      </PopoverContentPortal>
    );
  }
);

const DEFAULT_PAGE_PADDING: [number, number, number, number] = [15, 15, 15, 15];

export const Popover = ({
  content,
  trigger,
  theme,
  onClose,
  onChangeActiveState,
  pagePadding = DEFAULT_PAGE_PADDING,
  closeOnClick = false,
  showOnMouseEnter = false,
  overlay,
  renderCaret = false,
  position,
  // NOTE: mounting with active: true currently doesn't work properly
  active: activeProp,
  positionOffset,
  caretDimensions = CARET_DIMENSIONS_DEFAULT,
  onSetCaretPosition,
  dataHcName,
  dataHcEventSection,
}: PopoverProps) => {
  const pagePaddingHorz = pagePadding[1] - pagePadding[3];
  const Content = useRef<HTMLDivElement>(null);
  const Button = useRef<PopoverButtonImperativeHandleRef>(null);

  const { active, setActiveState } = useActiveState({
    active: activeProp,
  });
  const [caretPosition, setCaretPosition] = useState<CaretPosition>({
    left: 0,
    width: `${caretDimensions.width}px`,
    height: `${caretDimensions.height}px`,
  });
  const [visibleCaretPosition, setVisibleCaretPosition] =
    useState<CaretPosition>({
      left: 0,
      top: 0,
      width: `${caretDimensions.width}px`,
      height: `${caretDimensions.height}px`,
    });
  const [contentPosition, setContentPosition] = useState<ContentPosition>({
    width: 'auto',
    anchor: 'bottom',
    left: 0,
    top: 0,
  });
  const handleDialogActiveState = useCallback(
    (newActive: boolean) => {
      setActiveState(newActive);
      onChangeActiveState?.(newActive);
    },
    [setActiveState, onChangeActiveState]
  );
  useEffect(() => {
    if (activeProp !== undefined && activeProp !== active) {
      handleDialogActiveState(activeProp);
    }
  }, [activeProp, handleDialogActiveState, active]);

  const handleMouseEnter = () => {
    handleDialogActiveState(true);
  };

  const handleCloseOverlay = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleDialogActiveState(false);
    onClose?.();
  };
  const caretBoundingRect =
    Button.current?.caretCurrent?.getBoundingClientRect();
  // Caret useEffect to determine its coords:
  useEffect(() => {
    const hiddenCaretRect =
      Button.current?.caretCurrent?.getBoundingClientRect();
    if (
      renderCaret &&
      (hiddenCaretRect?.top !== visibleCaretPosition.top ||
        hiddenCaretRect?.left !== visibleCaretPosition.left)
    ) {
      setVisibleCaretPosition({
        top: hiddenCaretRect?.top || 0,
        left: hiddenCaretRect?.left || 0,
        width: `${caretDimensions.width}px`,
        height: `${caretDimensions.height}px`,
      });
    }
  }, [
    caretBoundingRect,
    caretPosition.top,
    caretPosition.left,
    visibleCaretPosition.left,
    visibleCaretPosition.top,
    caretDimensions.height,
    caretDimensions.width,
    renderCaret,
  ]);

  const {
    anchorBottom,
    anchorTop,
    left: positionOffsetLeft = 0,
  } = positionOffset || {};

  useEffect(() => {
    const handleClose = (e?: MouseEvent) => {
      const isWithinBounds = (
        pos: { x: number; y: number },
        bounds: DOMRect
      ) => {
        const x1 = bounds.x;
        const x2 = x1 + bounds.width;
        const y1 = bounds.y;
        const y2 = y1 + bounds.height;
        return pos.x >= x1 && pos.x <= x2 && pos.y >= y1 && pos.y <= y2;
      };

      const isEventOutsideBoundsOfComponent = (e?: MouseEvent) => {
        if (!e || !Button || !Content) return true;
        if (showOnMouseEnter) {
          const pos = {
            x: e.clientX,
            y: e.clientY,
          };
          const inBoundsButtonDomRect =
            Button.current?.boundingRect().buttonBounds;
          const inBoundsCaretDomRect =
            Button.current?.boundingRect().caretBounds;
          const inBoundsButtonOrCaret =
            (Button &&
              Button.current &&
              inBoundsButtonDomRect &&
              isWithinBounds(pos, inBoundsButtonDomRect)) ||
            (inBoundsCaretDomRect && isWithinBounds(pos, inBoundsCaretDomRect));
          const inBoundsContent =
            Content &&
            Content.current &&
            isWithinBounds(pos, Content.current.getBoundingClientRect());
          return (
            (!inBoundsButtonOrCaret && !inBoundsContent) ||
            !Button ||
            !Button.current
          );
        } else if (!overlay && Button.current && e.target instanceof Node) {
          return (
            !Button.current.contains(e.target) &&
            (!Content.current ||
              (Content.current && !Content.current.contains(e.target)))
          );
        }
        return true;
      };
      const isOutsideBounds = isEventOutsideBoundsOfComponent(e);
      if (showOnMouseEnter) {
        if (active && isOutsideBounds) {
          handleDialogActiveState(false);
          onClose?.();
        }
      } else {
        if (active) {
          if (isOutsideBounds || closeOnClick) {
            handleDialogActiveState(false);
            onClose?.();
          }
        } else if (!isOutsideBounds) {
          handleDialogActiveState(true);
        }
      }
    };

    const positionContent = () => {
      if (
        !Button.current ||
        !Content.current ||
        !Button.current.boundingRect().buttonBounds ||
        !Button.current.popoverButtonCurrent
      )
        return;
      const ButtonElm = Button.current.popoverButtonCurrent;
      const ContentElm = Content.current;
      // If not a 'mouse over popover', close the popover if the trigger is not visible
      if (!showOnMouseEnter && !isElementVisible(ButtonElm, false)) {
        handleDialogActiveState(false);
        return;
      }
      const button = ButtonElm.getBoundingClientRect();
      const content = ContentElm.getBoundingClientRect();
      const width = Math.min(
        content.width,
        window.innerWidth - pagePaddingHorz
      );
      const height = Math.min(
        content.height,
        window.innerHeight - pagePaddingHorz
      );
      const defaultTop = Math.max(
        pagePadding[0],
        button.top + button.height - pagePadding[0]
      );
      const anchor =
        defaultTop + height > window.innerHeight ? 'top' : 'bottom';
      const buttonCenter = button.left + button.width / 2;
      const top =
        anchor === 'top'
          ? Math.max(pagePadding[0], button.top - height) - (anchorTop || 0)
          : Math.max(
              pagePadding[2],
              button.top + button.height - (anchorBottom || 0)
            );
      let computedLeft = buttonCenter - width / 2;
      if (position === 'left') {
        computedLeft = buttonCenter;
      } else if (position === 'right') {
        computedLeft = buttonCenter - content.width;
      } else if (position === 'midRight') {
        computedLeft = buttonCenter - content.width + content.width / 4;
      } else if (position === 'midLeft') {
        computedLeft = buttonCenter - content.width / 4;
      }
      const left =
        Math.max(
          Math.min(computedLeft, window.innerWidth - width - pagePadding[1]),
          pagePadding[3]
        ) + positionOffsetLeft;
      const accountForMinorGapBetweenCaretAndContent = -1;
      const hiddenCaret: CaretPosition = {
        left: button.width / 2,
        top:
          anchor === 'top'
            ? -caretDimensions.height
            : button.height - accountForMinorGapBetweenCaretAndContent,
        width: `${caretDimensions.width}px`,
        height: `${caretDimensions.height}px`,
      };

      const visibleCaret: CaretPosition = {
        left: Math.min(
          buttonCenter - left - caretDimensions.width / 2,
          window.innerWidth - caretDimensions.width - pagePaddingHorz
        ),
      };

      setCaretPosition(hiddenCaret);
      onSetCaretPosition?.(visibleCaret);
      setContentPosition({
        width: `${width}px`,
        left,
        top,
        anchor,
      });
    };

    const handlePositionChange = () => {
      window.requestAnimationFrame(positionContent);
    };

    positionContent();
    window.addEventListener('resize', handlePositionChange);
    window.addEventListener('scroll', handlePositionChange, true);
    if (showOnMouseEnter) {
      window.addEventListener('mousemove', handleClose);
    } else {
      window.addEventListener('click', handleClose);
    }

    return () => {
      window.removeEventListener('resize', handlePositionChange);
      window.removeEventListener('scroll', handlePositionChange, true);
      window.removeEventListener('click', handleClose);
      window.removeEventListener('mousemove', handleClose);
    };
  }, [
    pagePadding,
    pagePaddingHorz,
    positionOffsetLeft,
    anchorBottom,
    anchorTop,
    onClose,
    overlay,
    position,
    showOnMouseEnter,
    onSetCaretPosition,
    caretDimensions.height,
    caretDimensions.width,
    closeOnClick,
    active,
    handleDialogActiveState,
  ]);

  return (
    <>
      <PopoverButton
        active={active}
        anchor={contentPosition.anchor}
        caretPosition={caretPosition}
        ref={Button}
        theme={theme}
        button={trigger}
        className={theme?.Button}
        onMouseEnter={showOnMouseEnter ? handleMouseEnter : undefined}
        dataHcName={dataHcName}
        dataHcEventSection={dataHcEventSection}
      />
      {active ? (
        <PopoverContent
          ref={Content}
          theme={theme}
          content={content}
          renderCaret={renderCaret}
          visibleCaretPosition={visibleCaretPosition}
          contentPosition={contentPosition}
          overlay={overlay}
          handleCloseOverlay={handleCloseOverlay}
          dataHcName={dataHcName}
          dataHcEventSection={dataHcEventSection}
        />
      ) : null}
    </>
  );
};
