import { JSXElementConstructor } from 'react';

import { IconProps } from '../design-system';

import { Roles } from './Account.types';

export enum AppSlugs {
  // Note: If updating, graphql annotations MUST be updated also. See hcs-s3-upload-known-queries.js
  // Customer-Facing Frontend Apps Only, No ties to ehrm or billing
  Platform = 'Platform',
  OrgAdmin = 'Org Admin',
  UserAdmin = 'User Admin',
  // Internal Frontend Apps Only, Not created in ehrm
  AccessSolutionsPlatform = 'Access Solutions Platform',
  AccessCanaryCommand = 'Access Canary Command',
  AccessPartnerTools = 'Access Partner Tools',
  AgileOpsCommandCenter = 'AgileOps Command Center',
  CerberusExplorer = 'Cerberus Explorer',
  BulkData = 'Bulk Data',
  EngTools = 'Engineering Tools',
  ReportRepair = 'Report Repair',
  ReportBuilder = 'Report Builder',
  CanaryAdmin = 'Canary Admin',
  ClientAdmin = 'Client Admin',
  PlatformAdmin = 'Platform Admin',
  // Temporary role to give access to legacy admin during migration to CanaryCommand
  LegacyAdminUi = 'LegacyAdminUi',
  // HCS Products, Permissions linked to subscriptions
  PropertyExplorer = 'Value Report',
  DataExplorer = 'Analytics API',
  CanaryAI = 'CanaryAI',
  PortfolioMonitor = 'Portfolio Monitor',
  AcquisitionExplorer = 'Lead Feed',
  MarketInsights = 'Market Insights',
  AgileSuite = 'Order Manager',
}

export interface AppConfig {
  // appSlug and rootPath should be combined into 1 value in ehrm
  appSlug: AppSlugs;
  rootPath: `/${string}`;
  name: React.ReactNode;
  globalHeaderTitle?: string;
  Icon?: JSXElementConstructor<IconProps>;
  descriptionLong: string | string[];
  descriptionShort: string;
  marketingUrl?: string;
  marketingImage?: string;
  requiredRole?: Roles[];
  // TODO: Remove this when we get to ehrmantraut app permissions changes
  // Ehrmantraut does not know about this app
  feOnlyApp?: boolean;
  premiumDescription?: string | string[];
}

export enum AgileProductGroupings {
  AgileInsights = 'Agile Insights',
  AgileEvaluation = 'Agile Evaluation',
  AgileInspection = 'Agile Inspection',
}

export interface AgileSuiteProductConfig
  extends Omit<AppConfig, 'rootPath' | 'appSlug'> {
  // Keep consistent contract the AppConfig to reuse components more easily
  appSlug: AgileProductGroupings;
}
export interface AgileSuiteProductConfigExtended {
  hasAccess?: boolean;
  // Keep consistent contract the AppConfigExtended to reuse components more easily
  appConfig: AgileSuiteProductConfig;
}

export interface AppConfigExtended {
  hasAccess?: boolean;
  appConfig: AppConfig;
}
