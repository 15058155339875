import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { extraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { ENV, IS_SENTRY_ENABLED } from './constants/webapps.constants';

// docs say to call this before you mount your react root
export const initSentry = () => {
  try {
    // these __ variables will be replaced by vite build (see withSentry plugin)
    if (IS_SENTRY_ENABLED) {
      Sentry.init({
        dsn: __SENTRY_DSN__,
        dist: __SENTRY_DIST__,
        release: __SENTRY_RELEASE__,
        environment: ENV,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          extraErrorDataIntegration(),
        ],
        normalizeDepth: 10,
        // This seems to still occur even after using useCallback with useResizeObserver
        // here's a forum suggesting it can be ignored https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
        // For ResizeObserver loop completed with undelivered notifications, switching to just warning in console in non-prod environments (see App.tsx)
        ignoreErrors: [
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications',
          // Email link Microsoft Outlook crawler compatibility error
          // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
          'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
          'Request failed with status code 401',
          'Network Error',
        ],
      });
    }
  } catch (e) {
    console.warn('Sentry disabled');
  }
};
