import {
  HcsAdminOrgApiFilter,
  HcsAdminOrgApiParams,
  HcsAdminOrgApiRateLimitMultiplier,
  HcsAdminOrgFilterFields,
  HcsAdminOrgOptions,
  HcsAdminOrgUsageApiFilter,
  HcsAdminOrgUsageApiParams,
  HcsAdminOrgUsageFilterFields,
  HcsAdminOrgUsageOptions,
} from '@hcs/types';
import { camelCaseToSnakeCase, formatNumber } from '@hcs/utils';

import {
  HCS_ADMIN_ORG_FILTER_OPS,
  HCS_ADMIN_ORG_USAGE_FILTER_OPS,
  HCS_ADMIN_PAGE_SIZE_DEFAULT,
} from '../constants/hcsAdmin.constants';

import { stringifyHcsAdminFilters } from './hcsAdminFilters.utils';

export const prepHcsAdminOrgFiltersForApi = (
  allFilters: HcsAdminOrgOptions | undefined
): HcsAdminOrgApiParams => {
  const { pagination, filters } = allFilters || {};
  const { sortBy, sortOrder, page, pageSize } = pagination || {};
  const apiFilters: HcsAdminOrgApiFilter[] = [];
  let customFilter: HcsAdminOrgApiParams['q']['customFilter'];
  for (const nameStr in filters) {
    const name = nameStr as HcsAdminOrgFilterFields;
    const val = filters[name];

    if (name === 'active' && typeof val === 'boolean') {
      apiFilters.push({
        name: camelCaseToSnakeCase(name),
        val,
        op: 'eq',
      });
    } else if (name === 'activeSubscriptions' && typeof val === 'boolean') {
      customFilter = {
        activeSubscriptions: val,
      };
    } else if (name !== 'active' && typeof val === 'string') {
      apiFilters.push({
        name: camelCaseToSnakeCase(name),
        val: `%${val}%`,
        op: HCS_ADMIN_ORG_FILTER_OPS[name],
      });
    }
  }
  return stringifyHcsAdminFilters({
    page: page || 1,
    resultsPerPage: pageSize || HCS_ADMIN_PAGE_SIZE_DEFAULT,
    q: {
      filters: apiFilters,
      customFilter,
      orderBy: [
        {
          field: sortBy || 'name',
          direction:
            (sortOrder?.toLowerCase() as 'asc' | 'desc' | undefined) || 'asc',
        },
      ],
    },
  });
};

export const prepHcsAdminOrgUsageFiltersForApi = (
  allFilters: HcsAdminOrgUsageOptions | undefined
): HcsAdminOrgUsageApiParams => {
  const { pagination, filters } = allFilters || {};
  const { sortBy, sortOrder, page, pageSize } = pagination || {};
  const apiFilters: HcsAdminOrgUsageApiFilter[] = [];
  for (const nameStr in filters) {
    const name = nameStr as HcsAdminOrgUsageFilterFields;
    const val = filters[name];

    if (name === 'organization_id' && typeof val === 'number') {
      apiFilters.push({
        name,
        val: val.toString(),
        op: 'eq',
      });
    } else if (name !== 'organization_id' && typeof val === 'string') {
      apiFilters.push({
        name,
        val: `${val}%`,
        op: HCS_ADMIN_ORG_USAGE_FILTER_OPS[name],
      });
    }
  }
  return stringifyHcsAdminFilters({
    page: page || 1,
    resultsPerPage: pageSize || HCS_ADMIN_PAGE_SIZE_DEFAULT,
    q: {
      filters: apiFilters,
      orderBy: [
        {
          field: sortBy || 'created_at',
          direction:
            (sortOrder?.toLowerCase() as 'asc' | 'desc' | undefined) || 'desc',
        },
      ],
    },
  });
};

export const hcsAdminOrgApiRateLimitDurationDisplay = (val: number) => {
  if (val === 0) {
    return '0 days';
  }

  if (val % HcsAdminOrgApiRateLimitMultiplier.day === 0) {
    return `${formatNumber(val / HcsAdminOrgApiRateLimitMultiplier.day)} days`;
  }

  if (val % HcsAdminOrgApiRateLimitMultiplier.hour === 0) {
    return `${formatNumber(
      val / HcsAdminOrgApiRateLimitMultiplier.minute
    )} hours`;
  }

  if (val % HcsAdminOrgApiRateLimitMultiplier.minute === 0) {
    return `${formatNumber(
      val / HcsAdminOrgApiRateLimitMultiplier.minute
    )} minutes`;
  }

  return `${val} seconds`;
};
