import React, { ReactNode } from 'react';

import styles from './CanaryAISignupContainer.module.css';

interface Props {
  children: ReactNode;
}

export const CanaryAISignupContainer = ({ children }: Props) => {
  return <div className={styles.SignupContainer}>{children}</div>;
};
