import { useCallback, useEffect, useState } from 'react';

import { DomInput } from '@hcs/types';

import { useRerender } from './useRerender';
import { useResizeObserver } from './useResizeObserver';
import { useWindowResize } from './useWindowResize';

const STANDARD_RESIZE_DELAY = 300;

export const useParentDimensions = <T extends HTMLElement>(
  { ref, element }: DomInput<T>,
  callback?: (parentDimensions: DOMRect | undefined) => void,
  delay?: number
) => {
  const domElement = element || ref?.current;
  const parentElement = domElement?.parentElement;
  const [parentDimensions, setParentDimensions] = useState<DOMRect | undefined>(
    parentElement?.getBoundingClientRect()
  );
  const updateParentDimensions = useCallback(() => {
    if (parentElement) {
      const newParentDimensions = parentElement.getBoundingClientRect();
      if (
        newParentDimensions.height !== parentDimensions?.height ||
        newParentDimensions.width !== parentDimensions?.width
      ) {
        setParentDimensions(newParentDimensions);
        callback?.(newParentDimensions);
      }
    }
  }, [parentElement]);
  // Rerender once if the parent is mounted simultaneously
  const { rerenders } = useRerender({
    deps: [parentElement],
    shouldRerender: !!parentElement,
    max: 1
  });
  // Listen to window resizes
  useWindowResize(updateParentDimensions, delay || STANDARD_RESIZE_DELAY);
  // Listen to parent size changes
  useResizeObserver({ element: parentElement }, updateParentDimensions);
  // Update if manual rerender is triggered or domElement changes
  useEffect(updateParentDimensions, [domElement, rerenders]);

  return parentDimensions;
};
