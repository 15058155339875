import { useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { hcsConsole } from '@hcs/console';
import { ReportEventTypes, ReportId } from '@hcs/types';
import { logException } from '@hcs/utils';

import { ReportApiEventsContext } from '../context';
import { QUERY_KEY_DOCUMENT_BY_ID } from '../hooks/useDocumentById';
import { QUERY_KEY_DOCUMENT_ROLE } from '../hooks/useDocumentRole';
import { QUERY_KEY_REPORT } from '../hooks/useReport';
import { useSubscribeToReportEvents } from '../hooks/useSubscribeToReportEvents';

const expectedEvents: {
  eventType: ReportEventTypes;
  timeout: number;
}[] = [];
const TIMEOUT = 30 * 1000;

export const useReportEventsRecovery = (reportId: ReportId) => {
  const queryClient = useQueryClient();
  const { getConnectionStatus } = useContext(ReportApiEventsContext);
  const handleTimeout = () => {
    const message = `Report-API SSE: Report ID = ${reportId}, Expected ${
      expectedEvents[0]?.eventType
    } SSE but did not receive one after ${
      TIMEOUT / 1000
    } seconds. Event Source Status: ${getConnectionStatus()}`;
    hcsConsole.log(message);
    // Log error to sentry
    logException(message);
    // Invalidate report-api document data so it gets re-requested
    queryClient.invalidateQueries([QUERY_KEY_DOCUMENT_BY_ID]);
    queryClient.invalidateQueries([QUERY_KEY_DOCUMENT_ROLE]);
    queryClient.invalidateQueries([QUERY_KEY_REPORT]);
    // Remove the oldest expected event
    expectedEvents.splice(0, 1);
  };
  useEffect(
    () => () => {
      // Clear all timeouts on unmount
      expectedEvents.forEach(({ timeout }) => {
        window.clearTimeout(timeout);
      });
    },
    []
  );
  useSubscribeToReportEvents({
    callbackId: 'useReportEventsRecovery',
    onMessage: (reportEvent) => {
      const expectedIndex = expectedEvents.findIndex(
        (e) => e.eventType === reportEvent.type
      );
      const expected = expectedEvents.splice(expectedIndex, 1);
      window.clearTimeout(expected[0]?.timeout);
    },
  });
  return (eventType: ReportEventTypes) => {
    expectedEvents.push({
      eventType,
      timeout: window.setTimeout(handleTimeout, TIMEOUT),
    });
  };
};
