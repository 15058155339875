import { useFeatureFlagsForUser } from '@hcs/huell';
import { FeatureFlags } from '@hcs/types';

export const useOfferNowAvailable = () => {
  const featureFlagsQuery = useFeatureFlagsForUser();
  const { isSuccess, data } = featureFlagsQuery;
  if (isSuccess) {
    return {
      ...featureFlagsQuery,
      data: data[FeatureFlags.OfferNowAvailable] || false,
    };
  } else {
    return {
      ...featureFlagsQuery,
      data: undefined,
    };
  }
};
