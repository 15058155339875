import { SVGProps, useSvg } from '@hcs/hooks';

import HouseCanaryLogoBlackSvg from './hc-logo_black.svg?react';
import HouseCanaryLogoFullColorSvg from './hc-logo_full-color.svg?react';
import HouseCanaryLogomarkFullColorSvg from './hc-logomark_full-color.svg?react';

export const HouseCanaryLogoFullColor = (props: SVGProps) =>
  useSvg(HouseCanaryLogoFullColorSvg, props);

export const HouseCanaryLogoBlack = (props: SVGProps) =>
  useSvg(HouseCanaryLogoBlackSvg, props);

export const HouseCanaryLogomarkFullColor = (props: SVGProps) =>
  useSvg(HouseCanaryLogomarkFullColorSvg, props);
