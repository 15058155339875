import { NumberSettings } from 'accounting';
import * as yup from 'yup';

import { formatNumber } from './formatters.utils';

interface FormatNumConfig {
  shouldFormatNumber: boolean;
  formatNumberOptions?: NumberSettings;
}

export const formatNumForInput = (
  value: number | string | null,
  config: FormatNumConfig = { shouldFormatNumber: true }
) => {
  if (value != null) {
    if (config.shouldFormatNumber && typeof value === 'number') {
      return formatNumber(value, config.formatNumberOptions);
    } else {
      return value.toString();
    }
  } else {
    return '';
  }
};

export const numInputStrStrip = (strValue: string) => {
  // replace anything that's not a digit, negative sign, or dot with empty string
  return strValue.replace(/[^\d\-.]/g, '');
};

interface ParseNumConfig {
  isFloat: boolean;
}

export const parseNumFromInput = (
  strValue: string,
  config: ParseNumConfig = { isFloat: false }
) => {
  let strStripped = '';
  // replace anything that's not a digit, negative sign, or dot with empty string
  strStripped = numInputStrStrip(strValue);
  // don't try to parse if the number ends with a dot as the user is still likely entering data
  if (config.isFloat && strStripped.endsWith('.')) {
    if (
      // Remove the last character if we end with decimal point and we have entered one earlier
      strStripped.endsWith('.') &&
      (strStripped.match(/\./g) || []).length > 1
    ) {
      return strStripped.substring(0, strStripped.length - 1);
    }
    return strStripped;
    // don't try to parse if the value is a dash as the user is still likely entering data
  } else if (strStripped === '-') {
    return strStripped;
  }

  const parsed = config.isFloat
    ? parseFloat(strStripped)
    : parseInt(strStripped);
  const newValue = isNaN(parsed) ? null : parsed;
  return newValue;
};

export const nDecimalPlaceValidationFactory = (
  numDecimalPlaces: number | undefined = 1
) => {
  /*
   * SECURITY: Found non-literal argument to RegExp Constructor,
   * it will be code and not user input that controls the non-literal argument @jdimattia
   */
  // eslint-disable-next-line security/detect-non-literal-regexp
  const decimalRegex = new RegExp(
    `^-?([0-9]+)?(.[0-9]{1,${numDecimalPlaces}})?$`
  );
  return (val: number | null | undefined) => {
    if (val === null || val === undefined || val.toString().length === 0) {
      return true;
    }

    return decimalRegex.test(val.toString());
  };
};

const ONE_DECIMAL_VALIDATION_NAME = 'oneDecimal';
const ONE_DECIMAL_ERROR = 'One decimal place allowed';

export interface GetRangeMinYupOptions<
  ActiveFieldNameType extends string | void = void
> {
  required?: boolean;
  isActiveFieldName?: ActiveFieldNameType;
  minValue?: number;
  shouldFormatNumber?: boolean;
  positive?: boolean;
  decimalPlaces?: number;
}
export const getRangeMinYup = <ActiveFieldNameType extends string | void>(
  options: GetRangeMinYupOptions<ActiveFieldNameType> = {}
) => {
  const {
    required = false,
    isActiveFieldName,
    minValue,
    shouldFormatNumber = true,
    positive = true,
    decimalPlaces
  } = options;
  const POSITIVE_MESSAGE = 'Min must be positive';
  const REQUIRED_MESSAGE = 'Min is required';
  const NUMBER_MESSAGE = 'Min must be a number';

  let returnYup = yup.number().typeError(NUMBER_MESSAGE).nullable(true);

  if (positive) {
    // yup has a built in positive but it does not consider 0 to be positive. min(0) includes zero
    returnYup = returnYup.min(0, POSITIVE_MESSAGE);
  }
  // doing explicit undefined check as 0 is falsey
  if (minValue !== undefined) {
    returnYup = returnYup.min(
      minValue,
      `Min value must be at least ${
        shouldFormatNumber ? formatNumber(minValue) : minValue
      }`
    );
  }

  if (required) {
    if (isActiveFieldName) {
      returnYup = returnYup.when(
        isActiveFieldName,
        (isActiveFieldValue, schema) => {
          return isActiveFieldValue
            ? schema.required(REQUIRED_MESSAGE)
            : schema;
        }
      );
    } else {
      returnYup = returnYup.required(REQUIRED_MESSAGE);
    }
  } else {
    returnYup = returnYup.notRequired();
  }

  if (decimalPlaces !== undefined) {
    returnYup = returnYup.test(
      ONE_DECIMAL_VALIDATION_NAME,
      ONE_DECIMAL_ERROR,
      nDecimalPlaceValidationFactory(decimalPlaces)
    );
  }

  return returnYup;
};

export interface GetRangeMaxYupOptions<
  MinFieldNameType extends string,
  ActiveFieldNameType extends string | void = void
> {
  minFieldName: MinFieldNameType;
  required?: boolean;
  isActiveFieldName?: ActiveFieldNameType;
  maxValue?: number;
  shouldFormatNumber?: boolean;
  positive?: boolean;
  decimalPlaces?: number;
}
export const getRangeMaxYup = <
  MinFieldNameType extends string,
  ActiveFieldNameType extends string | void
>(
  options: GetRangeMaxYupOptions<MinFieldNameType, ActiveFieldNameType>
) => {
  const {
    required = false,
    minFieldName,
    isActiveFieldName,
    maxValue,
    shouldFormatNumber = true,
    positive = true,
    decimalPlaces
  } = options;
  const POSITIVE_MESSAGE = 'Max must be positive';
  const REQUIRED_MESSAGE = 'Max is required';
  const MORE_THAN_MIN_MESSAGE = 'Max value must be more than Min value';
  const NUMBER_MESSAGE = 'Max must be a number';

  let returnYup = yup
    .number()
    .typeError(NUMBER_MESSAGE)
    .nullable(true)
    // when the min field is not null, then use the '.min' validation
    // to check if this max field is greater
    .when(minFieldName, {
      is: (val: number) => val !== null,
      then: yup
        .number()
        .nullable(true)
        .min(yup.ref(minFieldName), MORE_THAN_MIN_MESSAGE)
    });

  if (positive) {
    // yup has a built in positive but it does not consider 0 to be positive. min(0) includes zero
    returnYup = returnYup.min(0, POSITIVE_MESSAGE);
  }

  // doing explicit undefined check as 0 is falsey
  if (maxValue !== undefined) {
    returnYup = returnYup.max(
      maxValue,
      `Max value must be no more than ${
        shouldFormatNumber ? formatNumber(maxValue) : maxValue
      }`
    );
  }

  if (required) {
    if (isActiveFieldName) {
      returnYup = returnYup.when(
        isActiveFieldName,
        (isActiveFieldValue, schema) => {
          return isActiveFieldValue
            ? schema.required(REQUIRED_MESSAGE)
            : schema;
        }
      );
    } else {
      returnYup = returnYup.required(REQUIRED_MESSAGE);
    }
  } else {
    returnYup = returnYup.notRequired();
  }

  if (decimalPlaces !== undefined) {
    returnYup = returnYup.test(
      ONE_DECIMAL_VALIDATION_NAME,
      ONE_DECIMAL_ERROR,
      nDecimalPlaceValidationFactory(decimalPlaces)
    );
  }

  return returnYup;
};

/*
 * SECURITY: the regex has the potential to run for a long time and block the event loop (depending on input),
 * but the effect of that would just be crashing the user's tab @jdimattia
 */
// allows up to one decimal place or integers
// eslint-disable-next-line security/detect-unsafe-regex
const ONE_DECIMAL_REGEX = /^-?([0-9]+)?(\.[0-9]{1})?$/;
export const oneDecimalPlaceValidation = (val: number | null | undefined) => {
  if (val === null || val === undefined || val.toString().length === 0)
    return true;

  return ONE_DECIMAL_REGEX.test(val.toString());
};
