import { MarketInsightsIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

import MarketInsightsProductImage from '../assets/product-images/market-insights-image.jpg';

export const APP_CONFIG_MARKET_INSIGHTS: AppConfig = {
  name: 'Market Insights',
  globalHeaderTitle: 'Market Insights',
  appSlug: AppSlugs.MarketInsights,
  rootPath: '/market-insights',
  Icon: MarketInsightsIcon,
  descriptionLong: 'Explore data-driven market trends with Market Insights.',
  descriptionShort: 'Explore new markets and gain insights.',
  marketingImage: MarketInsightsProductImage,
  premiumDescription: [
    'Unlock premium Market Insights.',
    'Includes: Data downloads, favorite markets, and macro insights.',
  ],
};

export enum VIEW_PATHS_MARKET_INSIGHTS {
  SEARCH = 'search',
  MSAS = 'msas',
  MSA_REPORT = 'msas/:msaId',
  ZIPS = 'msas/:msaId/zips',
  ZIP_REPORT = 'msas/:msaId/zips/:zipcode',
  SIGN_UP = 'sign-up',
  SELF_SERVICE_SETUP = 'setup',
}
