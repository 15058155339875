import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAccount } from '@hcs/auth';
import { hcsConsole } from '@hcs/console';
import { useToastSlice } from '@hcs/toast';
import {
  DocumentRoles,
  PatchDocumentArg,
  ReportApiDocument,
  ReportBase,
  ReportEventTypes,
  ReportId,
} from '@hcs/types';
import { jsonPatchApplyEnhanced } from '@hcs/utils';
import { logException } from '@hcs/utils';

import { ReportApi } from '../api';
import { usePendingOptimisticUpdates } from '../hooks/usePendingOptimisticUpdates';
import { QUERY_KEY_REPORT } from '../hooks/useReport';
import { useReportEventsRecovery } from '../hooks/useReportEventsRecovery';

import { QUERY_KEY_DOCUMENT_ROLE } from './useDocumentRole';

export const useDocumentPatch = (
  reportId: ReportId,
  options?: Omit<
    UseMutationOptions<
      ReportApiDocument[] | null,
      AxiosError,
      PatchDocumentArg,
      { previousDocumentRoleData?: ReportApiDocument[] }
    >,
    'mutationFn'
  >
) => {
  const { data: account } = useAccount();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const queryClient = useQueryClient();
  const { addPendingOperations } = usePendingOptimisticUpdates();
  const expectEvent = useReportEventsRecovery(reportId);
  return useMutation(
    async (patchDocumentArg: PatchDocumentArg) => {
      const report = queryClient.getQueryData<ReportBase>([
        QUERY_KEY_REPORT,
        patchDocumentArg.reportId,
      ]);
      const clientPatchId = `${account?.user.id}-${new Date().getTime()}`;
      // When we create the mutation we create an associative array of operations to a counter
      // Whenever we process this operation for a matching document id, role and our own user id
      // in the SSE (useDocumentRole), we decrement the counter.

      addPendingOperations({
        reportId: report?.id || patchDocumentArg.reportId,
        documentId: patchDocumentArg.document.documentId,
        operations: patchDocumentArg.operations,
      });

      return await ReportApi.patchDocument({
        ...patchDocumentArg,
        clientPatchId,
      });
    },
    {
      ...options,
      // Optimistically Update Documents
      // https://react-query.tanstack.com/guides/optimistic-updates
      onMutate: async (patchDocumentArg) => {
        expectEvent(ReportEventTypes.DocumentsUpdated);
        const queryKey = [
          QUERY_KEY_DOCUMENT_ROLE,
          patchDocumentArg.reportId,
          patchDocumentArg.document.role,
        ];
        // Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        // Snapshot the previous value
        const previousDocumentRoleData =
          queryClient.getQueryData<ReportApiDocument[]>(queryKey);

        const patchedDocument = jsonPatchApplyEnhanced(
          patchDocumentArg.document,
          patchDocumentArg.operations
        ).newDocument;

        // Optimistically update to the new value
        queryClient.setQueryData<ReportApiDocument[]>(
          queryKey,
          previousDocumentRoleData?.map((d) =>
            d.documentId === patchDocumentArg.document.documentId
              ? patchedDocument
              : d
          ) || []
        );
        hcsConsole.log(
          'Report-Api: Optimistic Update Applied | ',
          patchedDocument
        );
        // Return a context object with the snapshotted value
        options?.onMutate?.(patchDocumentArg);
        return { previousDocumentRoleData };
      },
      onSuccess: async (documents, variables, context) => {
        // This works better than the code before which glitched out if you changed buttons too fast
        const uniqueRoles = new Set<DocumentRoles>();
        documents?.forEach((document) => {
          if (!uniqueRoles.has(document.role)) {
            uniqueRoles.add(document.role);
          }
        });
        options?.onSuccess?.(documents, variables, context);
      },
      onError: async (e, variables, context) => {
        toastOpen({
          type: 'error',
          title: 'Report update failed',
          message: 'We have been notified about the error',
        });
        logException(`Patch Document Failed: ${JSON.stringify(variables)}`);
        options?.onError?.(e, variables, context);
      },
    }
  );
};
