import { Roles } from '../auth';
import { FeatureFlags, ReportPreferences } from '../huell';
import { PdfTemplateIds } from '../pdf-service';
import { CompFields, PropertyStateFields } from '../property-state';
import { ReportTypes } from '../report-api/Report.types';

import { DocumentRoles } from './Document.types';

export enum ReportConfigSlugs {
  AgileInsights = 'agile-insights',
  AppraisalCompare = 'appraisal-compare',
  PexpDynamic = 'pexp-dynamic',
  PexpEffectiveDateDynamic = 'pexp-effectivedate-dynamic',
  PexpStatic = 'pexp-static',
  BpoReport = 'bpo-report',
}
export enum ReportFeaturesSupportTypes {
  Any = 'any',
  All = 'all',
  MoreThanOne = '>1',
}
export enum ReportFeatures {
  AppraisalComparison = 'APPRAISAL_COMPARISON',
  AppraisalComps = 'APPRAISAL_COMPS',
  AppraisalSubjectValue = 'APPRAISAL_SUBJECT_VALUE',
  AvmBreakdown = 'AVM_BREAKDOWN',
  CompsPricingMarketStrategyComments = 'COMPS_PRICING_MARKET_STRATEGY_COMMENTS',
  CompsSaleTopListPriceRatioCharts = 'COMPS_SALE_TO_LIST_PRICE_RATIO_CHARTS',
  CompTop4 = 'COMPS_TOP_4',
  DexpAnalysisComps = 'DEXP_ANALYSIS_COMPS',
  DexpAnalysisRentalComps = 'DEXP_ANALYSIS_RENTAL_COMPS',
  EditableLinks = 'EDITABLE_LINKS',
  NearbyPropertiesChart = 'NEARBY_PROPERTIES_CHART',
  SimilarPropertiesChart = 'SIMILAR_PROPERTIES_CHART',
  MonthsOfSupplyChart = 'MONTHS_OF_SUPPLY_CHART',
  DaysOnMarketChart = 'DAYS_ON_MARKET_CHART',
  OutputWeb = 'OUTPUT_WEB',
  OutputPdf = 'OUTPUT_PDF',
  OutputExcel = 'OUTPUT_EXCEL',
  MlsSheet = 'MLS_SHEET',
  Heatmaps = 'HEATMAPS',
  SubjectFlood = 'SUBJECT_FLOOD',
  SubjectStreetview = 'SUBJECT_STREETVIEW',
  SubjectPhotosCurrent = 'SUBJECT_PHOTOS_CURRENT',
  SubjectPhotosAll = 'SUBJECT_PHOTOS_ALL',
  HcAvm = 'HC_AVM',
  ComparableValue = 'COMPARABLE_VALUE',
  AdjustedAvm = 'ADJUSTED_AVM',
  UserOpinionOfPrice = 'USER_OPINION_OF_PRICE',
  RentalHcAvm = 'RENTAL_HC_AVM',
  RentalComparableValue = 'RENTAL_COMPARABLE_VALUE',
  RentalUserOpinionOfPrice = 'RENTAL_USER_OPINION_OF_PRICE',
  CompsHcSuggested = 'COMPS_HC_SUGGESTED',
  CompsSelect = 'COMPS_SELECT',
  CompsAdjust = 'COMPS_ADJUST',
  CompsFarmView = 'COMPS_FARM_VIEW',
  CompsSavedFilterSets = 'COMPS_SAVED_FILTER_SETS',
  CompsEditDetails = 'COMPS_EDIT_DETAILS',
  CompsAdjustedAvm = 'COMPS_ADJUSTED_AVM',
  CompsFilterGeojson = 'COMPS_FILTER_GEOJSON',
  CompsFilterKeyword = 'COMPS_FILTER_KEYWORD',
  CompsFilterDistance = 'COMPS_FILTER_DISTANCE',
  CompsSelectByAddress = 'COMPS_SELECT_BY_ADDRESS',
  CompsSelectByListing = 'COMPS_SELECT_BY_LISTING',
  CompsHcAdjustmentDate = 'COMPS_HC_ADJUSTMENT_DATE',
  CompsHcAdjustmentDetails = 'COMPS_HC_ADJUSTMENT_DETAILS',
  CompsListingRemarks = 'COMPS_LISTING_REMARKS',
  CompsNearbyFilteredCharts = 'COMPS_NEARBY_FILTERED_CHARTS',
  CompsSimilarPropertiesCharts = 'COMPS_SIMILAR_PROPERTIES_CHARTS',
  CompsPhotosCurrent = 'COMPS_PHOTOS_CURRENT',
  CompsTransactionHistory = 'COMPS_TRANSACTION_HISTORY',
  CompsStreetview = 'COMPS_STREETVIEW',
  Tags = 'TAGS',
  ReportDataRefresh = 'REPORT_DATA_REFRESH',
  ReportEditHistory = 'REPORT_EDIT_HISTORY',
  SubjectTransactionHistory = 'SUBJECT_TRANSACTION_HISTORY',
  SubjectForecastZipcode = 'SUBJECT_FORECAST_ZIPCODE',
  SubjectForecastBlock = 'SUBJECT_FORECAST_BLOCK',
  SubjectPropertyDetails = 'SUBJECT_PROPERTY_DETAILS',
  SubjectDataSources = 'SUBJECT_DATA_SOURCES',
  SubjectListingRemarks = 'SUBJECT_LISTING_REMARKS',
  SubjectEditDetails = 'SUBJECT_EDIT_DETAILS',
  SubjectEditCondition = 'SUBJECT_EDIT_CONDITION',
  SubjectComment = 'SUBJECT_COMMENT',
  CobrandedReport = 'COBRANDED_REPORT',
  IntraOrgLinks = 'INTRA_ORG_SHARING',
  PublicLinks = 'PUBLIC_LINKS',
  LimitedReportGeolocation = 'LIMITED_REPORT_GEOLOCATION',
  LimitedReportAvm = 'LIMITED_REPORT_AVM',
  LimitedReportMls = 'LIMITED_REPORT_MLS',
  RentalCompsHcSuggested = 'RENTAL_COMPS_HC_SUGGESTED',
  RentalCompsSelect = 'RENTAL_COMPS_SELECT',
  RentalCompsAdjust = 'RENTAL_COMPS_ADJUST',
  RentalCompsSavedFilterSets = 'RENTAL_COMPS_SAVED_FILTER_SETS',
  RentalCompsEditDetails = 'RENTAL_COMPS_EDIT_DETAILS',
  RentalCompsFarmView = 'RENTAL_COMPS_FARM_VIEW',
  RentalCompsFilterGeojson = 'RENTAL_COMPS_FILTER_GEOJSON',
  RentalCompsFilterKeyword = 'RENTAL_COMPS_FILTER_KEYWORD',
  RentalCompsFilterDistance = 'RENTAL_COMPS_FILTER_DISTANCE',
  RentalCompsSelectByAddress = 'RENTAL_COMPS_SELECT_BY_ADDRESS',
  RentalCompsSelectByListing = 'RENTAL_COMPS_SELECT_BY_LISTING',
  RentalCompsHcAdjustmentDate = 'RENTAL_COMPS_HC_ADJUSTMENT_DATE',
  RentalCompsHcAdjustmentDetails = 'RENTAL_COMPS_HC_ADJUSTMENT_DETAILS',
  RentalCompsListingRemarks = 'RENTAL_COMPS_LISTING_REMARKS',
  RentalCompsPhotosCurrent = 'RENTAL_COMPS_PHOTOS_CURRENT',
  RentalCompsTransactionHistory = 'RENTAL_COMPS_TRANSACTION_HISTORY',
  RentalCompsStreetview = 'RENTAL_COMPS_STREETVIEW',
  Inspection = 'INSPECTION',
  MarketAnalysisChart = 'MARKET_ANALYSIS_CHART',
  ReconciliationComments = 'RECONCILIATION_COMMENTS',
  RealtorDetails = 'REALTOR_DETAILS',
}

// Tuple of preference key and array of acceptable preference values
type ReportPreferencesFeatureConfig<P extends keyof ReportPreferences> = {
  reportPreferencesKey: P;
  acceptableValues: ReportPreferences[P][];
}[];

export interface ReportFeatureConfig {
  name: string;
  type: ReportFeatureTypes;
  description: string | string[];
  reportFeature: ReportFeatures;
  documentRoles: DocumentRoles[];
  fields?: (PropertyStateFields | CompFields)[];
  // Optional feature flag that controls visibilty of the report feature
  featureFlag?: FeatureFlags;
  // Optional preferences that control visibilty of the report feature
  reportPreferences?: {
    supportDefault: boolean;
    values: ReportPreferencesFeatureConfig<keyof ReportPreferences>;
  };
}
export interface ReportConfigApi {
  // Base report-type that define subject type and time period
  reportType: ReportTypes;
  // Machine-readable ID for the report config
  reportConfigSlug: ReportConfigSlugs;
  // Important applicationName / ehrmantrautComponentName need to stay in sync
  // Ehrmantraut application slug (e.g. "Value Report")
  applicationName: string;
  applicationSlug: string;
  componentName: string;
  // Ehrmantraut Component
  ehrmantrautComponentName?: string;
  // Report features supported
  reportFeatures: ReportFeatures[]; // List of strings from the spreadsheet
  // Customer-facing name for the product
  productName: string;
  // Customer-facing name for the report
  reportName: string;
  // Can external users make changes to the report -- this should effect reportPermissions on the report response
  clientEditable: boolean;
  // ID of the PDF template associated w/ the report (optional until we have a dynamic template)
  pdfTemplateId?: PdfTemplateIds; // enum
  // Optional Internal HC Roles that are allowed to edit the report (used for AEs) -- this should effect reportPermissions on the report response
  internalEditRoles?: Roles[] | null;
}
export interface ReportConfig extends ReportConfigApi {
  reportFeaturesSupport: Partial<{ [key in ReportFeatures]: boolean }>;
  documentRoles: Partial<{ [key in DocumentRoles]: boolean }>;
  reportFeaturesByType: Partial<{
    [key in ReportFeatureTypes]: ReportFeatures[];
  }>;
  fieldsSupport: Partial<
    { [key in PropertyStateFields]: boolean } & { [key in CompFields]: boolean }
  >;
}

export enum ReportFeatureTypes {
  Appraisal = 'appraisal',
  Market = 'market',
  Output = 'output',
  SubjectProperty = 'subject',
  PropertyValue = 'value',
  PropertyValueRental = 'value_rental',
  Comps = 'comps',
  Data = 'data',
  Sharing = 'sharing',
  RentalComps = 'rental_comps  ',
  Inspection = 'inspection',
}
