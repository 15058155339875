import { CanaryAIIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

const CanaryAIName = (
  <div>
    CanaryAI
    <span
      style={{
        color: 'rgb(12, 68, 255)',
        marginLeft: '2px',
        fontWeight: 100,
      }}
    >
      beta
    </span>
  </div>
);

export const APP_CONFIG_CANARYAI: AppConfig = {
  name: CanaryAIName,
  appSlug: AppSlugs.CanaryAI,
  rootPath: `/canaryai`,
  Icon: CanaryAIIcon,
  descriptionLong: 'Make housing data conversational with CanaryAI.',
  descriptionShort:
    'Make housing data conversational with CanaryAI. Convert complex data into easy-to-understand conversations.',
};

export const CANARYAI_ADDED_TO_WAITLIST = 'canaryaiAddedToWaitlist';

export enum VIEW_PATHS_CANARYAI {
  SIGN_UP = 'sign-up',
  SELF_SERVICE_SETUP = 'setup',
}
