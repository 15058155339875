import { GraphIcon } from '@hcs/design-system';
import { AppConfig,AppSlugs } from '@hcs/types';

import PortfolioProductImage from '../assets/product-images/portfolio-monitor-image.png';

export const APP_CONFIG_PORTFOLIO: AppConfig = {
  name: 'Portfolio Monitor',
  appSlug: AppSlugs.PortfolioMonitor,
  rootPath: `/monitor`,
  Icon: GraphIcon,
  descriptionLong:
    'Actively manage your portfolios by configuring notifications and analyzing data.',
  descriptionShort:
    'Actively manage your portfolios by configuring notifications and analyzing data.',
  marketingUrl:
    'https://www.housecanary.com/products/data-and-analytics/?utm_source=solutions&utm_medium=platform&utm_campaign=learn_more',
  marketingImage: PortfolioProductImage,
};

export enum VIEW_PATHS_PORTFOLIO {
  NEW_PORTFOLIO = 'new-portfolio',
  UPLOAD = 'upload',
  PORTFOLIO_SETUP = 'portfolio-setup',
  ALL_PORTFOLIOS = 'all-portfolios',
  PORTFOLIO = 'portfolio/:portfolioId',
  PORTFOLIO_DOWNLOAD_UPDATED_ASSETS = 'portfolio/:portfolioId/download/:s3Identifier',
}
