import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import { formatMoney, unformat } from '@hcs/utils';

import { InfoTooltipProps } from '../../popovers/InfoTooltip';

import styles from './Ledger.module.css';

interface LedgerItemDetail {
  label: ReactNode;
  value: number | null | undefined;
  columns?: ReactNode[];
}
interface LedgerItem extends LedgerItemDetail {
  className?: string;
  details?: LedgerItemDetail[];
}

interface LedgerTotal extends LedgerItemDetail {
  editable?: boolean;
  disabled?: boolean;
  onChange?: (v: number | null) => void;
  onBlur?: (v: number | null) => void;
  infoTooltip?: Omit<InfoTooltipProps, 'dataHcName'>;
}
export interface LedgerProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  formatter?: (v: number | null | undefined) => string;
  items: LedgerItem[];
  total: LedgerTotal;
  detailsVisible?: boolean;
}
export const Ledger = ({
  dataHcName,
  className,
  items,
  formatter = formatMoney,
  total,
  detailsVisible = true,
}: LedgerProps) => {
  const [inputValue, setInputValue] = useState(
    total.value == null ? null : formatter(total.value)
  );
  useEffect(() => {
    const propValue = total.value == null ? null : formatter(total.value);
    if (inputValue !== propValue) {
      setInputValue(propValue);
    }
  }, [total.value]);
  return (
    <section
      data-hc-name={dataHcName}
      className={classNames(styles.Ledger, className)}
    >
      <table className={styles.Items}>
        {items.map((item, i) => {
          return (
            <>
              <tr
                className={classNames(styles.Item, item?.className, {
                  [styles.hasDetails]: !!item.details?.length,
                })}
                key={`accounting-item-${i}`}
              >
                <td
                  data-hc-name={`${dataHcName}-item-label`}
                  className={styles.ItemLabel}
                  valign="bottom"
                >
                  {item.label}
                </td>
                {item.columns?.map((c, i) => (
                  <td
                    key={`${i}`}
                    className={styles.ItemLabel}
                    valign="bottom"
                    align="right"
                  >
                    {c}
                  </td>
                ))}

                <td
                  valign="bottom"
                  data-hc-name={`${dataHcName}-item-value`}
                  className={styles.ItemValue}
                >
                  {formatter(item.value)}
                </td>
              </tr>
              {detailsVisible &&
                item.details &&
                item.details?.map((itemDetail, d) => {
                  return (
                    <tr
                      key={`accounting-item-${i}-${d}`}
                      className={styles.Detail}
                    >
                      <td className={styles.DetailLabel} valign="bottom">
                        {itemDetail.label}
                      </td>
                      {itemDetail.columns?.map((c, i) => (
                        <td
                          key={`${i}`}
                          className={styles.DetailLabel}
                          align="right"
                          valign="bottom"
                        >
                          {c}
                        </td>
                      ))}
                      <td className={styles.DetailValue} valign="bottom">
                        {formatter(itemDetail.value)}
                      </td>
                    </tr>
                  );
                })}
            </>
          );
        })}
      </table>
      <div
        className={classNames(styles.Total, {
          [styles.disabled]: total.disabled,
        })}
      >
        <div
          data-hc-name={`${dataHcName}-total-label`}
          className={styles.TotalLabel}
        >
          {total.label}
        </div>
        <div
          data-hc-name={`${dataHcName}-total-value`}
          className={styles.TotalValue}
        >
          {total.editable ? (
            <input
              className={classNames(styles.TotalInput, styles.TotalValue)}
              value={inputValue || ''}
              data-hc-name={`${dataHcName}-value-input`}
              disabled={total.disabled}
              onFocus={() => {
                if (inputValue !== null) {
                  setInputValue(unformat(inputValue).toString());
                }
              }}
              onChange={(e) => {
                setInputValue(e.target.value === '' ? null : e.target.value);
                total.onChange?.(
                  e.target.value ? unformat(e.target.value) : null
                );
              }}
              onBlur={() => {
                if (inputValue !== null) {
                  setInputValue(formatter(Number(inputValue)));
                }
                total.onBlur?.(inputValue ? unformat(inputValue) : null);
              }}
            />
          ) : (
            formatter(total.value)
          )}
        </div>
      </div>
    </section>
  );
};
