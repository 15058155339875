import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { DATA_HC_NAME_PAGE_AUTH_SPINNER } from '@hcs/auth';
import { LoadingSpinner } from '@hcs/design-system';
import { APP_CONFIG_ORG_ADMIN, VIEW_PATHS_ORG_ADMIN } from '@hcs/hc-products';
import { useOmSftpAccess } from '@hcs/order-manager';

export const NonOmSftpAccessPageRedirect = ({
  children
}: {
  children: ReactNode;
}) => {
  const { data: hasOmSftpAccess, isInitialLoading } = useOmSftpAccess();
  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />;
  } else if (!hasOmSftpAccess) {
    return (
      <Navigate
        to={`${APP_CONFIG_ORG_ADMIN.rootPath}/${VIEW_PATHS_ORG_ADMIN.ORG_USAGE}`}
        replace
      />
    );
  } else {
    return <>{children}</>;
  }
};
