import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ActionButtons } from '@hcs/design-system';
import { HcsAdminNewUsageQuery } from '@hcs/types';

import { useHcsAdminOrgUsageQueryCreate } from '../../hooks/useHcsAdminOrgUsageQueryCreate';
import { OrgUsageQueryDetailsCreate } from '../OrgUsageQueryDetails';

interface Props {
  actionButtonsPortalId?: string;
  onClose: () => void;
  orgId: number;
}

const ORG_USAGE_QUERY_INPUT_FORM_SCHEMA = yup.object().shape({
  address: yup.string(),
  authReqId: yup.string(),
  component: yup.string(),
  endReqTime: yup.string(),
  startReqTime: yup.string(),
});

export const OrgUsageQueryCreate = ({
  actionButtonsPortalId,
  onClose,
  orgId,
}: Props) => {
  const form = useForm<HcsAdminNewUsageQuery>({
    resolver: yupResolver(ORG_USAGE_QUERY_INPUT_FORM_SCHEMA),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const createUsageQuery = useHcsAdminOrgUsageQueryCreate(orgId);

  const handleSubmit = (data: HcsAdminNewUsageQuery) => {
    const submitData = Object.keys(data)
      .filter((field) => data[field as keyof HcsAdminNewUsageQuery] !== '')
      .reduce(
        (initial, field) => ({
          ...initial,
          [field]: data[field as keyof HcsAdminNewUsageQuery],
        }),
        {
          ignoreNonbillable: data.ignoreNonbillable,
          propertySuccessStatus: data.propertySuccessStatus,
        }
      );
    createUsageQuery.mutate(submitData);
    onClose();
  };

  const dataHcName = 'org-query-create-dialog';
  return (
    <FormProvider {...form}>
        <form data-hc-name={`${dataHcName}-form`}>
          <OrgUsageQueryDetailsCreate />
          <ActionButtons
            portalIdRender={actionButtonsPortalId}
            dataHcName={`${dataHcName}-actions`}
            actions={[
              {
                dataHcName: `${dataHcName}-save`,
                label: 'Generate Report',
                type: 'submit',
                disabled: !form.formState.isValid,
                onClick: form.handleSubmit(handleSubmit),
                primary: true,
              },
            ]}
          />
        </form>
      </FormProvider>
  );
};
