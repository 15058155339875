import React from 'react';

import {
  APP_CONFIG_CANARYAI,
  CANARYAI_ADDED_TO_WAITLIST,
  useCanaryAIWaitlistLocalStorageState,
  useIsCanaryAIWaitlistSignedUp,
} from '@hcs/hc-products';
import { SelfServiceSignUp } from '@hcs/self-service';

import { useNavigateToLogin } from '../../../access/hooks/useNavigateToLogin';
import { Titles } from '../../components/Titles';
import { useNavigateToCanaryAISelfServiceSetup } from '../../hooks';

import styles from './CanaryAISelfServiceSignUpPage.module.css';

export const CanaryAISelfServiceSignUpPage = () => {
  const isOnWaitlist = useIsCanaryAIWaitlistSignedUp();
  const navigateToLogin = useNavigateToLogin();
  const navigateToSetup = useNavigateToCanaryAISelfServiceSetup();
  const canaryaiWaitlistLocalStorageState =
    useCanaryAIWaitlistLocalStorageState();
  const onSuccess = () => {
    if (!isOnWaitlist) {
      canaryaiWaitlistLocalStorageState.actions.setItem(
        CANARYAI_ADDED_TO_WAITLIST,
        'true'
      );
    }
    navigateToSetup();
  };

  return (
    <div className={styles.CanaryAISelfServiceSignUpPage}>
      <Titles
        title="CanaryAI Beta Waitlist Signup"
        description="Introducing CanaryAI, the first GenAI assistant tailored for the real estate industry, powered by HouseCanary's property data and analytics on over 136M homes. Sign up for the beta waitlist and to be notified of product updates."
        dataHcName="canaryai-self-service-sign-up-header"
      />
      <SelfServiceSignUp
        className={styles.SignUp}
        appSlug={APP_CONFIG_CANARYAI.appSlug}
        navigateToLogin={navigateToLogin}
        onSuccess={onSuccess}
      />
    </div>
  );
};
