import { useComponentDidMount } from './useComponentDidMount';

export const useHideHelpButton = () => {
  const updateHelpButton = (style: string) => {
    const elements = document.querySelectorAll<HTMLElement>('#launcher');
    elements.forEach((element: HTMLElement) => {
      element.style.display = style;
    });
  };

  useComponentDidMount(() => {
    //On mount hide the help widget when available
    (async () => {
      while (document.querySelectorAll<HTMLElement>('#launcher').length === 0)
        await new Promise((resolve) => setTimeout(resolve, 1000));
      updateHelpButton('none');
    })();
    return () => {
      // on unmount show button
      updateHelpButton('block');
    };
  });
};
