import React from 'react';

import styles from './Titles.module.css';

interface Props {
  title: string;
  description: string;
  compact?: boolean;
  dataHcName: string;
}

export const Titles = ({
  title,
  description,
  compact = false,
  dataHcName,
}: Props) => {
  if (!title) {
    return null;
  }
  return (
    <div data-hc-name={dataHcName} className={styles.Titles}>
      <div
        data-hc-name={`${dataHcName}-title`}
        className={compact ? styles.TitleCompact : styles.Title}
      >
        {title}
      </div>
      {description && (
        <div
          data-hc-name={`${dataHcName}-description`}
          className={compact ? styles.DescriptionCompact : styles.Description}
        >
          {description}
        </div>
      )}
    </div>
  );
};
