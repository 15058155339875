import accounting, { CurrencySettings, NumberSettings } from 'accounting';
import { format as dateFnsFormat } from 'date-fns';

import {
  AgileSuitePdfOrderTypeIds,
  AvmQuality,
  CompsListTypes,
  DateStr,
  ListingStatusCerberus,
  MarketRiskLevel,
  OrderItemStatus,
  PropertyStateLocation,
  PropertyTypeEnum,
  ResoPropertyDetailsFragment,
  RiskLevel,
  RiskType,
  ValuationMethod,
  ValuationMethodLabel,
  ValuationMethodLabelRental,
  ValueTypes,
} from '@hcs/types';

import { parseDateString } from './dates.utils';
import { listingStatusNormalized } from './listingStatus.utils';
import { capitalizeFirstLetter } from './strings.utils';

// String to show when missing a formatted value
export const NULL_VALUE = '––';
// String to delimit multiple fields in Tables
export const MULTI_FIELD_DELIMITER = ' | ';

export const formatNumber = (
  value: number | null | undefined,
  options: NumberSettings = { precision: 0 }
) =>
  value === null || value === undefined
    ? NULL_VALUE
    : accounting.formatNumber(value, options);

export const formatNumberAbbrev = (
  value: number | null | undefined,
  options?: { fixed: number }
) => {
  if (value === null || value === undefined) {
    return NULL_VALUE;
  }
  if (value >= 1000000) {
    return `${accounting.toFixed(value / 1000000, options?.fixed || 1)}M`;
  } else if (value >= 20000) {
    return `${accounting.toFixed(value / 1000, 0)}K`;
  } else {
    return `${accounting.toFixed(value / 1000, 1)}K`;
  }
};

export const parseStringOrNumber = (
  value?: number | string
): number | undefined => {
  if (value !== undefined) {
    return typeof value === 'string' ? parseInt(value) : value;
  }
  return value;
};

export const formatMoney = (
  value: number | null | undefined,
  options: CurrencySettings<'$'> = { precision: 0 }
) => {
  if (value === null || value === undefined) {
    return `$${NULL_VALUE}`;
  } else {
    const isNegative = value < 0;
    const formatted = accounting.formatMoney(Math.abs(value), options);
    return `${isNegative ? '-' : ''}${formatted}`;
  }
};

export const formatMoneyPerMonth = (v: number | null | undefined) =>
  `${formatMoney(v)}/mo.`;

export const formatMoneyAbbrev = (
  value: number | null | undefined,
  options?: { fixed: number }
) => `$${formatNumberAbbrev(value, options)}`;

export const unformat = (value: string) => accounting.unformat(value);

export const formatSqFt = (value: number | null | undefined) =>
  `${formatNumber(value)} ft²`;

export const formatPricePerSqFt = (
  value: number | undefined | null,
  forceRound?: boolean
) => {
  const valueFormatted =
    value && value !== 0
      ? value > 100 || forceRound
        ? formatMoney(Math.round(value))
        : formatMoney(parseFloat(value.toFixed(2)), { precision: 2 })
      : NULL_VALUE;
  return `${valueFormatted}/ft²`;
};

export const formatDistanceMiles = (value: number | null | undefined) =>
  value == null ? NULL_VALUE : `${formatNumber(value, { precision: 2 })} mi`;

export const formatMissing = (
  value: number | string | boolean | undefined | null
) =>
  value === 'All' || value === null || value === undefined
    ? NULL_VALUE
    : value.toString();

export const formatStreetAddress = (
  location?: PropertyStateLocation['location'] | null
) => {
  if (!location?.address) return NULL_VALUE;
  const unitDisplay = location.unit ? ` ${location.unit}` : '';
  return `${location.address || ''}${unitDisplay}`;
};

export const formatCityStateZip = (
  location?: PropertyStateLocation['location'] | null
) => {
  const { city, state, zipcode } = location || {};
  return `${city || NULL_VALUE}, ${state || NULL_VALUE} ${
    zipcode || NULL_VALUE
  }`;
};

export const formatDateShort = (
  date: number | Date | DateStr | null | undefined | string
): string => {
  try {
    return !date
      ? NULL_VALUE
      : dateFnsFormat(
          typeof date === 'string' ? parseDateString(date) : date,
          'M/d/yyyy'
        );
  } catch (e) {
    return NULL_VALUE;
  }
};

export const formatDateLong = (
  date: number | Date | DateStr | null | undefined | string
): string => {
  try {
    return !date
      ? NULL_VALUE
      : dateFnsFormat(
          typeof date === 'string' ? parseDateString(date) : date,
          'MMM d, yyyy'
        );
  } catch (e) {
    return NULL_VALUE;
  }
};

export const formatDateTime = (
  date: number | Date | DateStr | null | undefined | string
): string => {
  try {
    return !date
      ? NULL_VALUE
      : dateFnsFormat(
          typeof date === 'string' ? parseDateString(date) : date,
          'M/d/yyyy h:mm a'
        );
  } catch (e) {
    return NULL_VALUE;
  }
};

export const formatTime = (
  date: number | Date | DateStr | null | undefined | string
): string => {
  try {
    return !date
      ? NULL_VALUE
      : dateFnsFormat(
          typeof date === 'string' ? parseDateString(date) : date,
          'h:mm a'
        );
  } catch (e) {
    return NULL_VALUE;
  }
};

export const formatValuationMethod = (
  valuationMethod: ValuationMethod,
  valueType: ValueTypes
) => {
  const labels =
    valueType === 'RENTAL' ? ValuationMethodLabelRental : ValuationMethodLabel;
  switch (valuationMethod) {
    case ValuationMethod.Avm:
      return labels[ValuationMethod.Avm];
    case ValuationMethod.Adjusted:
      return labels[ValuationMethod.Adjusted];
    case ValuationMethod.Comps:
      return labels[ValuationMethod.Comps];
    case ValuationMethod.UserEntered:
      return labels[ValuationMethod.UserEntered];
  }
};

export const formatSnakeCase = (str?: string | null) => {
  return str
    ? str
        .split('_')
        .map((w) => capitalizeFirstLetter(w.toLowerCase()))
        .join(' ')
    : NULL_VALUE;
};

export const formatKebabCase = (str?: string | null) => {
  return str
    ? str
        .split('-')
        .map((w) => capitalizeFirstLetter(w.toLowerCase()))
        .join(' ')
    : NULL_VALUE;
};

export const formatBoolYesNo = (v: boolean | null | undefined): string =>
  v ? 'Yes' : v === false ? 'No' : NULL_VALUE;

const PROPERTY_TYPE_LABELS: { [key in PropertyTypeEnum]: string } = {
  [PropertyTypeEnum.Agricultural]: 'Agricultural',
  [PropertyTypeEnum.Sfr]: 'Single Family Detached',
  [PropertyTypeEnum.Townhouse]: 'Townhouse',
  [PropertyTypeEnum.Condo]: 'Condominium',
  [PropertyTypeEnum.MultiFamily]: 'Multifamily',
  [PropertyTypeEnum.Manufactured]: 'Manufactured',
  [PropertyTypeEnum.Other]: 'Other',
  [PropertyTypeEnum.Commercial]: 'Commercial',
  [PropertyTypeEnum.Timeshare]: 'Timeshare',
  [PropertyTypeEnum.Land]: 'Vacant Lot',
  [PropertyTypeEnum.RentalUnit]: 'Rental Unit',
};

export const formatPropertyType = (propertyType?: PropertyTypeEnum | null) => {
  return propertyType ? PROPERTY_TYPE_LABELS[propertyType] : NULL_VALUE;
};

export const formatListingStatus = (
  listingStatus: ListingStatusCerberus | null | undefined
) => listingStatus;

export const formatListingStatusNormalized = (
  listingStatus: ListingStatusCerberus | null | undefined
) =>
  listingStatus
    ? formatListingStatus(listingStatusNormalized(listingStatus))
    : NULL_VALUE;

export const formatListingStatusNormalizedRental = (
  listingStatus: ListingStatusCerberus | null | undefined
) => formatListingStatusNormalized(listingStatus);

export const formatFullAddress = (
  location?: PropertyStateLocation['location'] | null
) =>
  location
    ? `${formatStreetAddress(location)}, ${formatCityStateZip(location)}`
    : NULL_VALUE;

export const formatMonthsAgo = (
  months: number | null | undefined,
  label: string
) => {
  if (months === null || months === undefined) return NULL_VALUE;
  if (months >= 12) {
    const years = Math.round(months / 12);
    return `${capitalizeFirstLetter(label)} prior ${years} year${
      years === 1 ? '' : 's'
    }`;
  } else {
    return `${capitalizeFirstLetter(label)} prior ${months} month${
      months === 1 ? '' : 's'
    }`;
  }
};

export const formatList = (
  list: (string | null | undefined)[] | null | undefined,
  operator: 'and' | 'or' = 'and'
): string => {
  if (!list) return NULL_VALUE;
  if (list[0] && list.length === 1) {
    return formatMissing(list[0]);
  } else if (list.length === 2) {
    return `${formatMissing(list[0])} ${operator} ${formatMissing(list[1])}`;
  } else {
    const last = list.slice(list.length - 1)[0];
    const rest = list.slice(0, list.length - 1);
    return last
      ? `${rest.map(formatMissing).join(', ')}, ${operator} ${formatMissing(
          last
        )}`
      : NULL_VALUE;
  }
};

export const formatPercent = (
  pct: number | null | undefined,
  intScale?: boolean,
  precision = 0
): string => {
  const scale = Math.pow(10, precision);
  if (pct == null) {
    return `${NULL_VALUE}%`;
  } else {
    return `${Math.round(pct * (intScale ? 1 : 100) * scale) / scale}%`;
  }
};

export const formatCondition = (condition: number | null | undefined) => {
  switch (condition) {
    case 6:
      return 'Nearly New';
    case 5:
      return 'Excellent';
    case 4:
      return 'Well Maintained';
    case 3:
      return 'Worn But Adequate';
    case 2:
      return 'Significant Repairs';
    case 1:
      return 'Uninhabitable';
    default:
      return NULL_VALUE;
  }
};

export const formatAvmQuality = (avmQuality: AvmQuality) => {
  switch (avmQuality) {
    case 'HIGH':
      return 'High';
    case 'MEDIUM':
      return 'Medium';
    case 'LOW':
      return 'Low';
    default:
      return NULL_VALUE;
  }
};

export const formatForDataHcName = (str: string) =>
  str.toLowerCase().replace(' ', '-');

export const formatTimestamp = (timestamp?: number | null) => {
  if (timestamp) {
    return new Date(timestamp * 1000).toLocaleString();
  } else {
    return NULL_VALUE;
  }
};

export const formatMarketRiskLevel = (risk?: MarketRiskLevel | null) => {
  return risk === MarketRiskLevel.Modest ? 'Neutral' : formatSnakeCase(risk);
};

export const ORDER_ITEM_STATUSES: { [key in OrderItemStatus]: string } = {
  '': 'All',
  AppraisalPending: 'Appraisal Pending',
  AppraisalRevisionPending: 'Appraisal Revision Pending',
  InspectionPending: 'Inspection Pending',
  InspectionComplete: 'Inspection Complete',
  InspectionCancelled: 'Inspection Cancelled',
  EvaluationComplete: 'Evaluation Complete',
  ReportPending: 'Report Pending',
  Complete: 'Complete',
  Cancelled: 'Cancelled',
  CancelPending: 'Cancel Pending',
  InspectionRevision: 'Inspection Revision',
  InspectionReview: 'Inspection Review',
  InspectionCorrectionReview: 'Inspection Correction Review',
  ValuationReview: 'Valuation Review',
  QCReview: 'Valuation Review',
  RevisionRequested: 'Revision Requested',
};
export const formatOrderItemStatus = (
  orderItemStatus: OrderItemStatus | null
) => {
  return ORDER_ITEM_STATUSES[orderItemStatus || ''];
};

const AGILE_PDF_PRODUCT_ABBREV_BY_ORDER_TYPE: {
  [key in AgileSuitePdfOrderTypeIds]: string;
} = {
  [AgileSuitePdfOrderTypeIds.AgileInsights]: 'AI',
  [AgileSuitePdfOrderTypeIds.AgileEvaluationExterior]: 'AE Ext',
  [AgileSuitePdfOrderTypeIds.AgileEvaluationExteriorPlus]: 'AE Ext+',
  [AgileSuitePdfOrderTypeIds.AgileEvaluationInterior]: 'AE Int',
  [AgileSuitePdfOrderTypeIds.AgileEvaluationInteriorPlus]: 'AE Int+',
  [AgileSuitePdfOrderTypeIds.InspectionExterior]: 'Insp Ext',
  [AgileSuitePdfOrderTypeIds.InspectionExteriorPlus]: 'Insp Ext+',
  [AgileSuitePdfOrderTypeIds.InspectionInterior]: 'Insp Int',
  [AgileSuitePdfOrderTypeIds.InspectionInteriorPlus]: 'Insp Int+',
  [AgileSuitePdfOrderTypeIds.BrokerPriceOpinionExterior]: 'BPO Ext',
};
export const formatAgileSuitePdfFromOrderTypeIdAbbrev = (
  orderTypeId: AgileSuitePdfOrderTypeIds
) => {
  return AGILE_PDF_PRODUCT_ABBREV_BY_ORDER_TYPE[orderTypeId];
};

export const formatBoolYesNoUnknown = (v: boolean | null | undefined): string =>
  v ? 'Yes' : v === false ? 'No' : 'Unknown';

export const formatListingFireplace = (
  fireplace?: Pick<
    ResoPropertyDetailsFragment,
    'fireplaceYN' | 'fireplacesTotal' | 'fireplaceFeatures'
  > | null
) => {
  if (fireplace) {
    const { fireplaceYN, fireplacesTotal, fireplaceFeatures } = fireplace;
    const results = [];
    if (fireplaceYN != null) {
      results.push(formatBoolYesNoUnknown(fireplaceYN));
    }
    if (fireplacesTotal) {
      results.push(`Count: ${formatNumber(fireplacesTotal)}`);
    }
    if (fireplaceFeatures?.length) {
      results.push(`Notes: ${fireplaceFeatures.join(', ')}`);
    }
    return results.length > 0 ? results.join('; ') : NULL_VALUE;
  } else {
    return NULL_VALUE;
  }
};

export const formatFirstLastName = ({
  firstName,
  lastName,
}: {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
}) => {
  return !firstName && !lastName ? NULL_VALUE : `${firstName} ${lastName}`;
};

export const formatFsdAsPercentage = (fsd: number | null | undefined) => {
  return formatPercent(fsd != null ? 1 - fsd : fsd, false);
};

export const formatRiskType = (riskType: RiskType): string => {
  return riskType === 'comparables' ? 'Comparables' : 'Valuation';
};

export const formatRiskLevel = (riskLevel: RiskLevel | null | undefined) => {
  switch (riskLevel) {
    case 'HIGH_RISK': {
      return 'High Risk';
    }
    case 'RISK': {
      return 'Needs Review';
    }
    case 'LOW_RISK': {
      return 'Low Risk';
    }
    default: {
      return NULL_VALUE;
    }
  }
};

export const formatRiskDescription = (
  riskLevelOverall: RiskLevel | null | undefined,
  riskLevelValue: RiskLevel | null | undefined,
  riskLevelComps: RiskLevel | null | undefined
) => {
  if (riskLevelValue === 'LOW_RISK' && riskLevelComps === 'LOW_RISK') {
    return 'The appraised value is within the expected range and has a low risk of inaccuracy. The selected comparables are similar to the subject property and have a low risk of resulting in an inaccurate valuation.';
  } else if (riskLevelValue === 'LOW_RISK' && riskLevelComps === 'RISK') {
    return 'The selected comparables are moderately similar to the subject property but the appraised value is within the expected range and has a low risk of inaccuracy.';
  } else if (riskLevelValue === 'LOW_RISK' && riskLevelComps === 'HIGH_RISK') {
    return 'Although the appraised value is within the expected range and has a low risk of inaccuracy, the comparable properties selected are not suitable for the subject. The appraisal should be reviewed for inaccuracies.';
  } else if (riskLevelValue === 'RISK' && riskLevelComps === 'LOW_RISK') {
    return '';
  } else if (riskLevelValue === 'RISK' && riskLevelComps === 'RISK') {
    return '';
  } else if (riskLevelValue === 'RISK' && riskLevelComps === 'HIGH_RISK') {
    return '';
  } else if (riskLevelValue === 'HIGH_RISK' && riskLevelComps === 'LOW_RISK') {
    return '';
  } else if (riskLevelValue === 'HIGH_RISK' && riskLevelComps === 'RISK') {
    return '';
  } else if (riskLevelValue === 'HIGH_RISK' && riskLevelComps === 'HIGH_RISK') {
    return 'The appraised value is outside of the expected range and has a high risk of inaccuracy. The selected comparable properties are not suitable for the subject.';
  }
  return '';
};

export const formatCompsListType = (compsListType: CompsListTypes): string => {
  switch (compsListType) {
    case 'appraisal':
      return 'Appraisal Comps';
    case 'compIDs':
      return 'Filtered Comps';
    case 'competitiveActive':
      return 'Competitive Active Listings';
    case 'competitiveClosed':
      return 'Competitive Closed Sales';
    case 'hcSuggestedComps':
      return 'HC Comps';
    case 'selected':
      return 'Selected Comps';
  }
};
