import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ActionButtons } from '@hcs/design-system';
import {
  OrganizationapiCreateOrgRequest,
  OrganizationapiOrgResponse,
} from '@hcs/types';

import { OrganizationForm } from '../../components/OrganizationForm/OrganizationForm';
import { useCreateOrganization } from '../../hooks/useCreateOrganization';

const FORM_SCHEMA = yup.object().shape({
  name: yup.string().required('Name is required').nullable(),
  m3terAccountId: yup.string().nullable(),
  active: yup.boolean(),
  enterprise: yup.boolean(),
  test: yup.boolean(),
});

const dataHcName = 'create-org-form';

interface Props {
  defaultValues?: Partial<OrganizationapiCreateOrgRequest>;
  onCancel: VoidFunction;
  onSuccess: (newOrg: OrganizationapiOrgResponse) => void;
  actionPortalIdRender?: string;
  className?: string;
}
export const CreateOrganizationForm = ({
  defaultValues,
  onCancel,
  className,
  onSuccess,
  actionPortalIdRender,
}: Props) => {
  const { mutate: createOrganization } = useCreateOrganization({ onSuccess });
  const form = useForm<OrganizationapiCreateOrgRequest>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      ...defaultValues,
      active: defaultValues?.active !== undefined ? defaultValues.active : true,
    },
  });
  const handleSubmit = (formData: OrganizationapiCreateOrgRequest) => {
    createOrganization(formData);
  };
  return (
    <FormProvider {...form}>
      <OrganizationForm className={className}>
        <ActionButtons
          dataHcName={`${dataHcName}-actions`}
          actions={[
            {
              dataHcName: `${dataHcName}-cancel`,
              label: 'Cancel',
              secondary: true,
              onClick: onCancel,
            },
            {
              dataHcName: `${dataHcName}-submit`,
              label: 'Create Org',
              primary: true,
              onClick: () => handleSubmit(form.getValues()),
            },
          ]}
          portalIdRender={actionPortalIdRender}
        />
      </OrganizationForm>
    </FormProvider>
  );
};
