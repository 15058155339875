import React from 'react';

import { TermsAndPrivacyLinks } from '@hcs/auth';
import { Card } from '@hcs/design-system';
import { SelfServiceConfirmAccount } from '@hcs/self-service';

import styles from './CanaryAISelfServiceSetupPage.module.css';
const dataHcName = 'canaryai-self-setup-page';

export const CanaryAISelfServiceSetupPage = () => {
  return (
    <>
      <Card dataHcName={dataHcName} className={styles.Card}>
        <SelfServiceConfirmAccount
          confirmationText={
            <span>
              A confirmation email was sent to your email address. Please
              confirm your email to join the CanaryAI
              <span
                style={{
                  color: 'rgb(12, 68, 255)',
                  marginLeft: '2px',
                  fontWeight: 100,
                }}
              >
                beta
              </span>{' '}
              waitlist.
            </span>
          }
        />
      </Card>
      <TermsAndPrivacyLinks />
    </>
  );
};
