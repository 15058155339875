import React from 'react';

import productHeroImage from '../../../../assets/images/canary-ai-product-hero.png';

import styles from './DemoImageContainer.module.css';

export const DemoImageContainer = () => {
  return (
    <div className={styles.CanaryAISignupImageContainer}>
      <img src={productHeroImage} className={styles.CanaryAISignupImage} />
    </div>
  );
};
