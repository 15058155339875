import React from 'react';

import {
  AddIcon,
  Button,
  Dialog,
  DIALOG_ACTIONS_PORTAL_ID,
} from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';

import { useUser } from '../../hooks';
import { useOrgRoles } from '../../hooks/useOrgRoles';

import { AddRoleToUserForm } from './AddRoleToUserForm';

import styles from './AddRoleToUserForm.module.css';
interface Props {
  userId: number | undefined;
}

const dialogTheme = { DialogContent: styles.DialogContent };
export const AddRoleToUserLauncher = ({ userId }: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const { data: user } = useUser(userId);
  const { data: orgRoles } = useOrgRoles(user?.organizationId);
  if (!orgRoles?.length) {
    return null;
  }
  return (
    <>
      <Button
        dataHcName="add-role"
        label="Add Role"
        icon={<AddIcon />}
        onClick={handleOpen}
      />
      <Dialog
        dataHcName="dialog"
        active={active}
        onClose={handleClose}
        theme={dialogTheme}
        title="Add Role to User"
      >
        <AddRoleToUserForm
          userId={userId}
          onSubmit={handleClose}
          onCancel={handleClose}
          actionPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
        />
      </Dialog>
    </>
  );
};
