import { TableCellProps, TableHeaderCellProps } from '../design-system';

export enum AssetParamIds {
  AddressSearch = 'address',
}

export interface AssetsParams {
  [AssetParamIds.AddressSearch]?: string;
}

export interface AddressObj {
  addressSlug: string;
  address: string;
  unit?: string | null;
  city: string;
  state: string;
  zipcode: string;
}
export interface PortfolioAsset {
  assetId: string;
  address: AddressObj;
  data: {
    ltv: number;
    lastUpdate: string;
    avm: number;
    closingDate: string;
    closingPrice: number;
    mlsStatus: string;
    loanContractDate: string;
    interestRate: number;
    yearBuilt: number;
    listingPrice: number;
    listingDate: string;
  };
}

export interface PortfolioAssets {
  assets: PortfolioAsset[];
  cursor: string;
}

export enum PortfolioAssetFields {
  ltv = 'ltv',
  lastUpdate = 'lastUpdate',
  closingDate = 'closingDate',
  closingPrice = 'closingPrice',
  mlsStatus = 'mlsStatus',
  loanContractDate = 'loanContractDate',
  interestRate = 'interestRate',
  yearBuilt = 'yearBuilt',
  listingPrice = 'listingPrice',
  listingDate = 'listingDate',
  avm = 'avm',
}

export interface PortfolioAssetContentCellProps {
  portfolioAsset: PortfolioAsset;
}
export interface PortfolioAssetFieldConfig {
  HeaderCell: {
    ({ ...tableHeaderCellProps }): JSX.Element;
    defaultProps: Partial<TableHeaderCellProps>;
  };
  ContentCell: {
    (props: PortfolioAssetContentCellProps): JSX.Element;
    defaultProps: Partial<TableCellProps>;
  };
  label: string;
}

export interface FetchPortfolioAssetsPayload {
  portfolioId: string;
  filters: AssetsParams;
  cursor: string | null;
}

export interface DeletePortfolioAssetsPayload {
  portfolioId: string;
  assetIdsArr: PortfolioAsset['assetId'][];
}

export interface AddPortfolioAssetsAddressPayload {
  addressSlug: string;
  // TODO: clientId will be used in v2 when we add a new client id input field to the ui.
  clientId?: string;
}
export interface AddPortfolioAssetsAddressErrorDict {
  status: string | string[];
}

export type AddPortfolioAssetsAddressError =
  | string
  | AddPortfolioAssetsAddressErrorDict;

export interface AddPortfolioAssetsPayload {
  portfolioId: string;
  addressesPayload: AddPortfolioAssetsAddressPayload[];
}

interface AddPortfolioAssetsResponseData {
  addressSlug: string;
  assetId: string;
}

export type AddPortfolioAssetsResponse = AddPortfolioAssetsResponseData[];

export interface ImportPortfolioAssetsPayload {
  portfolioId: string;
  formData: FormData;
}

export interface ImportPortfolioAssetsJobCreateErrorDict {
  status: string | string[];
}

export type ImportPortfolioAssetsCreateError =
  | string
  | ImportPortfolioAssetsJobCreateErrorDict;
