import React, { useMemo } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useSystemRolesByType } from '@hcs/auth';
import { Dropdown, DropdownOptionType } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { StatusMessage } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import { AdminFeatureOrgProps, FeatureFlags } from '@hcs/types';
import {
  UserApplicationPermissionsForm,
  UserApplicationUpdateExternal,
} from '@hcs/types';
import { logException } from '@hcs/utils';

import { useCreateInvitation } from '../../hooks/useCreateInvitation';
import { useIsFeatureFlagEnabled } from '../../hooks/useIsFeatureFlagEnabled';
import { useOrganizationLegacy } from '../../hooks/useOrganizationLegacy';
import { UserApplicationPermissionsFields } from '../UserApplicationPermissions/UserApplicationPermissionsFields';

import styles from './InviteUser.module.css';

interface CreateInviteForm extends UserApplicationPermissionsForm {
  firstName: string;
  lastName: string;
  email: string;
  role: number;
}
export const inviteValidationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .max(80)
    .email('Must be a valid email')
    .required('Email is required'),
});

type InviteUserProps = AdminFeatureOrgProps & {
  actionsPortalIdRender?: string;
  onSuccess?: VoidFunction;
};
interface InviteUserInternalFormProps {
  roleIdUser: number;
  roleIdAdmin: number;
  roleIdBroker: number;
  defaultValues: {
    role: number;
  };
  roleOptions: DropdownOptionType<number>[];
}
const dataHcName = 'invite-user';
const InviteUserInner = ({
  actionsPortalIdRender,
  defaultValues,
  roleOptions,
  roleIdAdmin,
  roleIdUser,
  roleIdBroker,
  onSuccess,
  ...adminFeatureOrgProps
}: InviteUserProps & InviteUserInternalFormProps) => {
  const { data: org } = useOrganizationLegacy(adminFeatureOrgProps);
  const orgId = adminFeatureOrgProps.orgId || org?.id;
  const formMethods = useForm<CreateInviteForm>({
    mode: 'onBlur',
    resolver: yupResolver(inviteValidationSchema),
    defaultValues: defaultValues,
  });

  const mutation = useCreateInvitation({
    onSuccess: () => {
      mutation.reset();
      formMethods.reset(defaultValues);
      onSuccess?.();
    },
  });

  const roleId = formMethods.watch('role');

  const onSubmit: SubmitHandler<CreateInviteForm> = (formData) => {
    if (!orgId) {
      logException('InviteUserDialog: onSubmit - orgId was unexpected null');
      return;
    }
    const {
      role,
      orderManagerRoles,
      firstName,
      lastName,
      email,
      ...userApplicationAccess
    } = formData;
    const isAdmin = role === roleIdAdmin;
    const applications: UserApplicationUpdateExternal[] = [];
    if (!isAdmin) {
      Object.values(userApplicationAccess).forEach((appAccess) => {
        if (appAccess?.state === 'deny') {
          applications.push(appAccess);
        }
      });
    }
    mutation.mutate({
      orgId,
      firstName,
      lastName,
      email,
      roles: [{ roleId: role }, ...(orderManagerRoles || [])],
      applications: applications.length ? applications : undefined,
    });
  };

  return (
    <div className={styles.ContentContainer}>
      <FormProvider {...formMethods}>
        <form>
          <DialogInputLayout
            label="First Name"
            required
            dataHcName={`${dataHcName}-first-name-field`}
            className={styles.FormRow}
          >
            <Controller
              name="firstName"
              control={formMethods.control}
              render={({ field }) => {
                return (
                  <Input
                    dataHcName={`${dataHcName}-first-name-field`}
                    placeholder="First Name"
                    {...field}
                    value={field.value || ''}
                    error={formMethods.formState.errors.firstName?.message}
                  />
                );
              }}
            />
          </DialogInputLayout>
          <DialogInputLayout
            label="Last Name"
            required
            dataHcName={`${dataHcName}-last-name-field`}
            className={styles.FormRow}
          >
            <Controller
              name="lastName"
              control={formMethods.control}
              render={({ field }) => {
                return (
                  <Input
                    dataHcName={`${dataHcName}-last-name-field`}
                    placeholder="Last Name"
                    {...field}
                    value={field.value || ''}
                    error={formMethods.formState.errors.lastName?.message}
                  />
                );
              }}
            />
          </DialogInputLayout>
          <DialogInputLayout
            label="Email"
            required
            dataHcName={`${dataHcName}-email-field`}
            className={styles.FormRow}
          >
            <Controller
              name="email"
              control={formMethods.control}
              render={({ field }) => {
                return (
                  <Input
                    dataHcName={`${dataHcName}-email-field-input`}
                    placeholder="Email"
                    {...field}
                    value={field.value || ''}
                    error={formMethods.formState.errors.email?.message}
                  />
                );
              }}
            />
          </DialogInputLayout>
          <DialogInputLayout
            label="Role"
            labelHelper="Admin users have access to all products"
            required
            dataHcName={`${dataHcName}-role-field`}
            className={styles.FormRow}
          >
            <Controller
              name="role"
              control={formMethods.control}
              render={({ field: { onChange, value } }) => (
                <Dropdown<number>
                  dataHcName={`${dataHcName}-role-field-dropdown`}
                  value={value}
                  options={roleOptions}
                  onSelect={onChange}
                />
              )}
            />
          </DialogInputLayout>
          {(roleId === roleIdUser || roleId === roleIdBroker) && (
            <UserApplicationPermissionsFields
              dataHcName={`${dataHcName}-edit-user-permissions`}
              {...adminFeatureOrgProps}
            />
          )}
        </form>
      </FormProvider>
      <StatusMessage
        show={mutation.isError}
        dataHcName={`${dataHcName}-error`}
        type="error"
        title={`An error occurred: ${
          mutation.error?.response?.data.message || mutation.error?.message
        }`}
      />
      <ActionButtons
        dataHcName={`${dataHcName}-actions`}
        portalIdRender={actionsPortalIdRender}
        actions={[
          {
            label: 'Invite',
            dataHcName: `${dataHcName}-invite-button`,
            loading: mutation.isLoading,
            disabled: mutation.isLoading || !formMethods.formState.isValid,
            onClick: () => formMethods.handleSubmit(onSubmit)(),
          },
        ]}
      />
    </div>
  );
};
export const InviteUser = (externalProps: InviteUserProps) => {
  const { data: rolesByType } = useSystemRolesByType();
  const roleIdUser = rolesByType?.['User'].id;
  const roleIdAdmin = rolesByType?.['Power User'].id;
  const roleIdBroker = rolesByType?.['Broker'].id;
  const orgId = Number(externalProps.orgId);
  const isBrokerPartnerEnabled = useIsFeatureFlagEnabled({
    orgId,
    featureFlag: FeatureFlags.BrokerPartner,
  });

  const internalProps = useMemo<InviteUserInternalFormProps | undefined>(() => {
    if (roleIdUser && roleIdAdmin) {
      const roleOptionsList = [
        {
          label: 'User',
          value: roleIdUser,
        },
        {
          label: 'Admin',
          value: roleIdAdmin,
        },
        ...((isBrokerPartnerEnabled && [
          {
            label: 'Broker',
            value: roleIdBroker,
          },
        ]) ||
          []),
      ];
      return {
        roleIdAdmin,
        roleIdUser,
        roleIdBroker,
        defaultValues: {
          role: roleIdUser,
        },
        roleOptions: roleOptionsList,
      } as InviteUserInternalFormProps;
    } else {
      return undefined;
    }
  }, [roleIdUser, roleIdAdmin, roleIdBroker, isBrokerPartnerEnabled]);

  if (internalProps) {
    return <InviteUserInner {...externalProps} {...internalProps} />;
  } else {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
};
