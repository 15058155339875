import React, { ReactNode } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { Skeleton } from '@hcs/design-system';
import {
  DialogInputLayout,
  FormFieldsInput,
  FormFieldsSwitch,
} from '@hcs/forms';

import styles from './OrganizationForm.module.css';

const MULTI_INPUT_THEME = { InputContainer: styles.MultiInputLine };
const dataHcName = 'org-form';
interface Props {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
}
export const OrganizationForm = ({ children, className, isLoading }: Props) => {
  const form = useFormContext();
  const inputSkeleton = (
    <Skeleton
      dataHcName={`${dataHcName}-skeleton`}
      type="input"
      className={styles.Input}
    />
  );
  return (
    <FormProvider {...form}>
      <form
        data-hc-name={`${dataHcName}-form`}
        className={classNames(styles.Form, className)}
      >
        <DialogInputLayout
          label="Name"
          required
          dataHcName={`${dataHcName}-name`}
        >
          {isLoading ? (
            inputSkeleton
          ) : (
            <FormFieldsInput
              name="name"
              label=""
              error={form.formState.errors.name?.message?.toString()}
              className={styles.Input}
            />
          )}
        </DialogInputLayout>
        <DialogInputLayout
          label="Salesforce"
          required={false}
          dataHcName={`${dataHcName}-salesforce`}
          theme={MULTI_INPUT_THEME}
        >
          {isLoading ? (
            <>
              {inputSkeleton}
              {inputSkeleton}
            </>
          ) : (
            <>
              <FormFieldsInput
                name="salesforceAccountId"
                label="Account ID"
                error={form.formState.errors.salesforceAccountId?.message?.toString()}
                className={styles.Input}
              />
              <FormFieldsInput
                name="salesforceLeadId"
                label="Lead ID"
                error={form.formState.errors.salesforceLeadId?.message?.toString()}
                className={styles.Input}
              />
            </>
          )}
        </DialogInputLayout>
        <DialogInputLayout
          label="m3ter"
          dataHcName={`${dataHcName}-salesforce`}
          theme={MULTI_INPUT_THEME}
          required={false}
        >
          {isLoading ? (
            <>
              {inputSkeleton}
              {inputSkeleton}
            </>
          ) : (
            <>
              <FormFieldsInput
                name="m3terAccountId"
                label="Account ID"
                error={form.formState.errors.m3terAccountId?.message?.toString()}
                className={styles.Input}
              />
              <FormFieldsInput
                name="m3terAccountCode"
                label="Account Code"
                error={form.formState.errors.m3terAccountCode?.message?.toString()}
                className={styles.Input}
              />
            </>
          )}
        </DialogInputLayout>
        <DialogInputLayout
          label="Zuora"
          dataHcName={`${dataHcName}-salesforce`}
          theme={MULTI_INPUT_THEME}
          required={false}
        >
          {isLoading ? (
            <>
              {inputSkeleton}
              {inputSkeleton}
            </>
          ) : (
            <>
              <FormFieldsInput
                name="zAccountId"
                label="Account ID"
                error={form.formState.errors.zAccountId?.message?.toString()}
                className={styles.Input}
              />
              <FormFieldsInput
                name="zAccountNumber"
                label="Account Number"
                error={form.formState.errors.zAccountNumber?.message?.toString()}
                className={styles.Input}
              />
            </>
          )}
        </DialogInputLayout>
        <DialogInputLayout
          label=""
          dataHcName={`${dataHcName}-salesforce`}
          theme={MULTI_INPUT_THEME}
          required={false}
        >
          <FormFieldsSwitch name="active" label="Active" />
          <FormFieldsSwitch name="enterprise" label="Enterprise" />
          <FormFieldsSwitch name="test" label="Test Account" />
        </DialogInputLayout>
        {children}
      </form>
    </FormProvider>
  );
};
