import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { useAppConfigsExtended, useUserIsConfirmed } from '@hcs/auth';
import { AppLauncher } from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import { APP_CONFIG_PORTFOLIO } from '@hcs/hc-products';
import { APP_CONFIG_AEXP } from '@hcs/hc-products';
import { APP_CONFIG_AGILE_SUITE } from '@hcs/hc-products';
import { APP_CONFIG_DEXP } from '@hcs/hc-products';
import { APP_CONFIG_PEXP } from '@hcs/hc-products';
import { ProductPreviewDialog } from '@hcs/hc-products';
import { useNavigateToAgileSuite } from '@hcs/hc-products';
import { useNavigateToPexp } from '@hcs/hc-products';
import { useNavigateToPlatformLanding } from '@hcs/hc-products';
import { AppConfig, AppConfigExtended, AppSlugs } from '@hcs/types';

import styles from './SolutionsPlatformAppLauncher.module.css';

const dataHcName = 'solutions-platform-app-launcher';
export const SolutionsPlatformAppLauncher = () => {
  const appConfigs: AppConfig[] = [
    APP_CONFIG_DEXP,
    APP_CONFIG_PEXP,
    APP_CONFIG_AEXP,
    APP_CONFIG_AGILE_SUITE,
    APP_CONFIG_PORTFOLIO,
  ];
  const { data: appConfigsExtended } = useAppConfigsExtended(appConfigs, {
    showUnauthorized: true,
  });
  const PEXP_REPORT_API = useExperienceFlag('PEXP_REPORT_API');
  const navigate = useNavigate();
  const navigateToPlatformLanding = useNavigateToPlatformLanding();
  const navigateToPexp = useNavigateToPexp();
  const [getAccessAppConfigExtended, setGetAccessAppConfigExtended] =
    useState<AppConfigExtended>();
  const navigateToAgileSuite = useNavigateToAgileSuite();
  const { data: isUserConfirmed, isInitialLoading: isUserConfirmedIsLoading } =
    useUserIsConfirmed();
  if (!isUserConfirmed || isUserConfirmedIsLoading) {
    return null;
  }
  return (
    <>
      <AppLauncher
        theme={{
          Content: styles.Content,
        }}
        dataHcName={dataHcName}
        appConfigsExtended={appConfigsExtended}
        onClickApp={(appConfigExtended) => {
          if (appConfigExtended.hasAccess) {
            if (
              appConfigExtended.appConfig.appSlug ===
                AppSlugs.PropertyExplorer &&
              PEXP_REPORT_API
            ) {
              navigateToPexp();
            } else if (
              appConfigExtended.appConfig.appSlug === AppSlugs.AgileSuite
            ) {
              navigateToAgileSuite();
            } else {
              navigate(appConfigExtended.appConfig.rootPath);
            }
          } else {
            setGetAccessAppConfigExtended(appConfigExtended);
          }
        }}
        viewAll={{
          label: 'View All Products',
          onClick: navigateToPlatformLanding,
        }}
      />
      <ProductPreviewDialog
        active={!!getAccessAppConfigExtended}
        appConfigExtended={getAccessAppConfigExtended}
        onClose={() => {
          setGetAccessAppConfigExtended(undefined);
        }}
      />
    </>
  );
};
