import { useLocalStorageState } from '@hcs/hooks';

import { CANARYAI_DEV_TOOLS_ENABLED } from '../constants';

export const useIsCanaryAIDevToolsEnabled = () => {
  const canaryaiDevToolsLocalStorageState =
    useCanaryAIDevToolsLocalStorageState();
  // SECURITY: this is only used to enable some debugging tools for this application. No sensitive
  // data is exposed to users as part of the dev tools
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  return (
    canaryaiDevToolsLocalStorageState.actions.getItem(
      CANARYAI_DEV_TOOLS_ENABLED
    ) === 'true'
  );
};

export const useCanaryAIDevToolsLocalStorageState = () => {
  const localStorageResults = useLocalStorageState(
    'canaryaiDevToolsLocalStorageEvent',
    [CANARYAI_DEV_TOOLS_ENABLED]
  );
  return localStorageResults;
};
