import { useLocalStorageState } from '@hcs/hooks';

import { CANARYAI_ADDED_TO_WAITLIST } from '../constants';

export const useIsCanaryAIWaitlistSignedUp = () => {
  const canaryaiWaitlistLocalStorageState =
    useCanaryAIWaitlistLocalStorageState();
  return (
    canaryaiWaitlistLocalStorageState.actions.getItem(
      CANARYAI_ADDED_TO_WAITLIST
    ) === 'true'
  );
};

export const useCanaryAIWaitlistLocalStorageState = () => {
  const localStorageResults = useLocalStorageState('canaryaiWaitlist', [
    CANARYAI_ADDED_TO_WAITLIST,
  ]);
  return localStorageResults;
};
