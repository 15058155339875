import React from 'react';
import classNames from 'classnames';

import { PropertyStateArgsProps, PropertyStateFields } from '@hcs/types';

import { getPropertyStateFieldValue } from '../../utils';

import styles from './PropertyBrokerageCredit.module.css';

interface Props extends PropertyStateArgsProps {
  className?: string;
  isRental: boolean;
}
const dataHcName = 'brokerage-credit';
export const PropertyBrokerageCredit = ({
  propertyStateArgs,
  className,
  isRental,
}: Props) => {
  const brokerageName = getPropertyStateFieldValue(
    isRental
      ? PropertyStateFields.listingAgentOfficeRental
      : PropertyStateFields.listingAgentOffice,
    propertyStateArgs
  )?.office;
  if (!brokerageName) return null;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.BrokerageCredit, className)}
    >
      {`Courtesy of ${brokerageName}`}
    </div>
  );
};
