import React, {
  ChangeEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { InputProps } from '@hcs/types';

import { Tooltip } from '../../../display/popovers/Tooltip';

import styles from './Input.module.css';

type ParentRef = HTMLInputElement | null;

export const Input = React.forwardRef<ParentRef, InputProps>(
  (props: InputProps, ref) => {
    const {
      dataHcName,
      className,
      theme,
      value,
      disabled,
      error,
      tooltipError = false,
      type,
      name,
      autoComplete = 'off',
      placeholder,
      role,
      required,
      maxLength,
      noBorders,
      onChange,
      onKeyUp,
      onBlur,
      onFocus,
    } = props;

    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      if (inputRef && inputRef.current && isFocused) {
        inputRef?.current.focus();
      }
    }, [error, inputRef]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event) {
        const value = event.target.value || '';
        onChange(value);
      }
    };
    const inputElement = (
      <input
        className={classNames(
          styles.InputElement,
          {
            [styles.ErrorHighlight]: error,
            [styles.disabled]: disabled,
            [styles.noBorders]: noBorders,
          },
          theme?.InputElement
        )}
        data-hc-name={dataHcName}
        name={name}
        onChange={handleChange}
        onKeyUp={onKeyUp}
        onBlur={(e) => {
          isFocused && setIsFocused(false);
          onBlur && onBlur(e);
        }}
        onFocus={(e) => {
          !isFocused && setIsFocused(true);
          onFocus && onFocus(e);
        }}
        placeholder={placeholder}
        role={role}
        disabled={disabled}
        required={required}
        autoComplete={autoComplete}
        type={type}
        maxLength={maxLength}
        value={value}
        ref={inputRef}
      />
    );

    return (
      <div className={classNames(styles.Input, className, theme?.Container)}>
        {error && tooltipError ? (
          <Tooltip
            dataHcName={`${dataHcName}-input-tooltip`}
            theme={{
              Trigger: styles.Trigger,
            }}
            trigger={inputElement}
            label={error}
          />
        ) : (
          inputElement
        )}
        {error && !tooltipError ? (
          <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
            {error}
          </div>
        ) : null}
      </div>
    );
  }
);
