import React, {
  cloneElement,
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { useRerender, useResizeObserver } from '@hcs/hooks';
import { TableHeaderProps, TableProps, TableRowProps } from '@hcs/types';
import { logException } from '@hcs/utils';

import { Skeleton } from '../../../global/loading-errors-null/Skeleton/Skeleton';

import styles from './Table.module.css';

const isTableRow = (
  child: ReactElement
): child is ReactElement<TableRowProps> => {
  return !!child.props.isTableRow;
};

const isTableHeader = (
  child: ReactElement
): child is ReactElement<TableHeaderProps> => {
  return !!child.props.isTableHeader;
};

export const Table = ({
  theme,
  dataHcName,
  dataHcEventSection,
  className,
  belowRows,
  style = {},
  children,
  lazyRenderBuffer = 200,
  infiniteScroll,
  skeletonConfig,
  scrollDisabled = false,
}: TableProps) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const infiniteScrollFooter = useRef<HTMLDivElement>(null);
  const scrollWidth = scrollContainer.current?.scrollWidth || 0;
  const clientWidth = scrollContainer.current?.clientWidth || 0;
  const [scrollInfo, setScrollInfo] = useState({
    scrollTop: scrollContainer.current?.scrollTop || 0,
    scrollLeft: scrollContainer.current?.scrollLeft || 0,
    offsetHeight: scrollContainer.current?.offsetHeight || 0,
    fullyScrolledHorzStart: false,
    fullyScrolledHorzEnd: false,
  });

  const handleScroll = () => {
    if (scrollContainer.current) {
      const {
        scrollLeft,
        scrollTop,
        scrollHeight,
        clientHeight,
        offsetHeight,
      } = scrollContainer.current;

      setScrollInfo({
        scrollTop,
        scrollLeft,
        offsetHeight,
        fullyScrolledHorzStart: scrollLeft === 0,
        fullyScrolledHorzEnd: scrollWidth - scrollLeft === clientWidth,
      });

      /**
       * User scrolled to bottom of table.
       *
       * scrollTop is a non-rounded number,
       * while scrollHeight and clientHeight are rounded
       * so the only way to determine if the scroll area is scrolled to the bottom is
       * by seeing if the scroll amount is close enough to some threshold.
       * ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
       */
      if (
        scrollHeight -
          clientHeight -
          scrollTop -
          (infiniteScrollFooter.current?.clientHeight || 0) <
          1 &&
        infiniteScroll
      ) {
        infiniteScroll.onScrollToBottom();
      }
    }
  };
  // Force a single rerender so the scroll container is in the DOM
  useRerender({
    deps: [scrollContainer.current],
    shouldRerender: !scrollContainer.current?.offsetHeight,
    max: 1,
  });
  // Update scroll info when the scroll container resizes
  useResizeObserver(
    { ref: scrollContainer },
    useCallback(() => {
      if (scrollContainer?.current) {
        setScrollInfo({
          scrollTop: scrollContainer.current.scrollTop,
          scrollLeft: scrollContainer.current.scrollLeft,
          offsetHeight: scrollContainer.current.offsetHeight,
          fullyScrolledHorzStart: scrollContainer.current.scrollLeft === 0,
          fullyScrolledHorzEnd:
            scrollContainer.current?.scrollWidth -
              scrollContainer.current?.scrollLeft ===
            scrollContainer.current?.clientWidth,
        });
      }
    }, [scrollContainer])
  );

  const {
    headerToRender,
    rowsToRender,
    spacerHeightAbove,
    spacerHeightBelow,
    skeletonRows,
  } = useMemo(() => {
    const header: ReactElement[] = [];
    const rows: ReactElement[] = [];
    // Amount to offset sticky rows
    let stickyOffset = 0;
    // Offset of row from top of table, used for lazy rendering
    let totalOffset = 0;
    // Track height of unrendered rows for scrollbar consistency
    let heightAbove = 0;
    let heightBelow = 0;

    const processChildren = (toProcess: ReactNode) => {
      React.Children.forEach(toProcess, (child, i) => {
        if (child === null) return;
        if (!React.isValidElement(child)) {
          logException(
            '[Table] Invalid Child. Only TableRow, TableHeader, or Fragment can be a child of Table.'
          );
          return null;
        }
        if (!isTableRow(child) && !isTableHeader(child)) {
          if (child.type === Fragment) {
            return processChildren(child.props.children);
          }
          logException(
            '[Table] Invalid Child. Only TableRow, TableHeader, or Fragment can be a child of Table.'
          );
          return null;
        }
        // Determine whether the child is in the visible window or above/below it.
        const visibilityStatus =
          child.props.height === undefined
            ? 'visible'
            : totalOffset >
              scrollInfo.scrollTop + scrollInfo.offsetHeight + lazyRenderBuffer
            ? 'below'
            : totalOffset + child.props.height <
              scrollInfo.scrollTop - lazyRenderBuffer
            ? 'above'
            : 'visible';

        if (
          visibilityStatus !== 'visible' &&
          child.props.height &&
          !child.props.sticky
        ) {
          // Keep track of the height of this unrendered child so we can set the correct spacer height for a natural scrollbar
          if (visibilityStatus === 'above') {
            heightAbove += child.props.height;
          } else if (visibilityStatus === 'below') {
            heightBelow += child.props.height;
          }
        } else {
          const renderedIndex = header.length + rows.length;
          // Augment visible children props
          if (isTableHeader(child)) {
            // Specify the exact type of child
            header.push(
              cloneElement(child, {
                ...child.props,
                key: child.key,
                dataHcName: child.props.dataHcName || `${dataHcName}-header`,
              })
            );
          } else {
            // Specify the exact type of child
            rows.push(
              cloneElement(child, {
                ...child.props,
                key: child.key,
                className: child.props.className,
                stickyOffset: child.props.sticky ? stickyOffset : undefined,
                isScrollingHorz: !!scrollInfo.scrollLeft,
                dataHcName:
                  child.props.dataHcName ||
                  `${dataHcName}-row-${i}-${renderedIndex}`,
              })
            );
          }
        }
        if (child.props.height) {
          totalOffset += child.props.height;
          if (child.props.sticky) {
            stickyOffset += child.props.height;
          }
        }
      });
    };

    processChildren(children);

    const skeletonRows: ReactElement[] = [];
    if (skeletonConfig) {
      skeletonRows.push(
        ...[...Array(skeletonConfig.rows)].map((_, rowIdx) => (
          <tr
            data-hc-name={`${dataHcName}-row-skeleton`}
            className={styles.SkeletonRow}
            key={`skeleton-row-${rowIdx}`}
            style={{ height: skeletonConfig.rowHeight || 'auto' }}
          >
            <td
              className={styles.SkeletonCell}
              colSpan={skeletonConfig.colCount}
            >
              <Skeleton
                type={'fillInline'}
                dataHcName={`${dataHcName}-cell-skeleton`}
              />
            </td>
          </tr>
        ))
      );
    }

    return {
      rowsToRender: rows,
      headerToRender: header,
      spacerHeightAbove: heightAbove,
      spacerHeightBelow: heightBelow,
      skeletonRows,
    };
  }, [children, scrollInfo]);

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      style={style}
      className={classNames(
        styles.Table,
        className,
        {
          [styles.fullyScrolledHorzStart]:
            scrollInfo.fullyScrolledHorzStart || skeletonConfig?.isLoading,
          [styles.fullyScrolledHorzEnd]:
            scrollInfo.fullyScrolledHorzEnd || skeletonConfig?.isLoading,
        },
        theme?.Table
      )}
    >
      <div
        ref={scrollContainer}
        className={classNames(styles.TableContent, {
          [styles.scrollEnabled]: !scrollDisabled,
          [styles.scrollDisabled]: scrollDisabled,
        })}
        onScroll={() => handleScroll()}
      >
        <table>
          {headerToRender}
          <tbody>
            <tr
              className={styles.LazySpacer}
              style={{ height: `${spacerHeightAbove}px` }}
            />
            {rowsToRender}
            {skeletonConfig?.isLoading && skeletonRows}
            <tr
              className={styles.LazySpacer}
              style={{ height: `${spacerHeightBelow}px` }}
            />
          </tbody>
        </table>
        {infiniteScroll && infiniteScroll.footer && (
          <div ref={infiniteScrollFooter}>{infiniteScroll.footer}</div>
        )}
      </div>
      {belowRows || null}
    </div>
  );
};
