import React from 'react';

export const AcceptInvitationPage = React.lazy(
  () => import('./AcceptInvitationPage')
);
export const ForgotPasswordPage = React.lazy(
  () => import('./ForgotPasswordPage')
);
export const LoginPage = React.lazy(() => import('./LoginPage'));
export const ResetPasswordPage = React.lazy(
  () => import('./ResetPasswordPage')
);
export const ConfirmUserPage = React.lazy(() => import('./ConfirmUserPage'));

export const SamlLoginPage = React.lazy(() => import('./SamlLoginPage'));
