import { useNavigate } from 'react-router';

import { APP_CONFIG_CANARYAI, VIEW_PATHS_CANARYAI } from '@hcs/hc-products';

export const useNavigateToCanaryAISelfServiceSetup = () => {
  const navigate = useNavigate();
  return () => {
    navigate(
      `${APP_CONFIG_CANARYAI.rootPath}/${VIEW_PATHS_CANARYAI.SELF_SERVICE_SETUP}`
    );
  };
};
