import React from 'react';

import { KeywordSearchListingRemarks } from '@hcs/keyword-search';
import { ReportFeatures, ReportId } from '@hcs/types';

import { useSubjectDocument } from '../../hooks';

interface Props {
  reportId: ReportId;
  className?: string;
  keywordSearchKey?: string;
}
export const SUBJECT_PUBLIC_REMARKS_FEATURES = [
  ReportFeatures.SubjectListingRemarks,
];
export const SubjectPublicRemarks = ({
  reportId,
  ...keywordSearchProps
}: Props) => {
  const { isInitialLoading, data: subjectDocument } =
    useSubjectDocument(reportId);
  return (
    <KeywordSearchListingRemarks
      isLoading={isInitialLoading}
      {...keywordSearchProps}
      date={
        subjectDocument?.data.propertyState.complexFieldsSale?.currentListDate
      }
      remarks={
        subjectDocument?.data.propertyState.listingDetailsSale?.remarks?.find(
          (r) => r?.type === 'Public'
        )?.value
      }
    />
  );
};
